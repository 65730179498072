import { Injectable } from '@angular/core';
import { Confirmation, ConfirmationService, MessageService } from 'primeng/api';
import { Exhibition } from '../model/exhibition';
import { File } from '../model/file';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    constructor(public messageService: MessageService, public confirmationService: ConfirmationService) {}

    createBlankExhibition() {
        return {
            id: 0,
            title: 'Неки наслов',
            subtitle: 'Неки поднаслов',
            thumbnail: null,
            create_date: new Date(),
            note: 'Белешка',
            published: false,

            background_color: '#FAE7CC',
            background_image_portrait: null,
            background_image_landscape: null,

            pages: [
                {
                    introductory: false,
                    gallery: false,
                    page_number: 1,
                    row_number: 20,
                    column_number: 20,
                    language: 'Српски',

                    background_color: '#FAE7CC',
                    background_image_portrait: null,
                    background_image_landscape: null,

                    sections: [
                        {
                            section_order: 1,
                            x_coordinate: 1,
                            y_coordinate: 1,
                            width: 20,
                            height: 20,
                            content: null,

                            background_color: '#FAE7CC',
                            background_image_portrait: null,
                            background_image_landscape: null
                        }
                    ]
                }
            ]
        };
    }

    findMaxHeightForImageDiv(files: (File | null)[], imageProperties: any, width: number): void {
        let maxImageHeight: number = 200;
        const allHeights: number[] = [];
        files.forEach((item) => {
            const img = new Image();
            if (item) {
                img.src = item.file;
            } else {
                img.src = '/assets/images/thumbnail/placeholder-thumbnail.png';
            }
            img.onload = () => {
                const widthRatio: number = width / img.width;
                const imgHeight: number = img.height * (widthRatio < 1 ? widthRatio : 1);
                maxImageHeight = imgHeight > maxImageHeight ? imgHeight : maxImageHeight;
                //imageProperties.height = 380 + 'px';
                allHeights.push(maxImageHeight);
                imageProperties.height = Math.max(...allHeights) + 'px';
            };
        });
    }

    displayToastrMessage(message: string, summary: string = '', severity: string = 'success', life: number = 3000) {
        this.messageService.add({ severity, summary, detail: message, life });
    }

    displayConfirmationService(
        message: string,
        header: string = '',
        accept: () => void,
        acceptLabel: string = 'Потврди',
        rejectLabel: string = 'Откажи',
        icon: string = 'pi pi-exclamation-triangle'
    ) {
        this.confirmationService.confirm({
            message,
            header,
            acceptLabel,
            rejectLabel,
            icon,
            accept: () => {
                accept();
            }
        });
    }
}
