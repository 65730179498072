import { StateContext } from '@ngxs/store';
import { Page } from 'src/modules/shared/model/page';
import { ExhibitionStateModel } from '../exhibition.state';
import { AddPage } from '../exhibition.state.actions';

export const addPage = (ctx: StateContext<ExhibitionStateModel>, action: AddPage) => {
    const newPage: Page = {
        ...action.payload.page,
        language: ctx.getState().selectedLanguage
    };

    ctx.patchState({
        pages: [...ctx.getState().pages, newPage],
        selectedSection: 0
    });
};
