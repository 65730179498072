<div class="card basicCommands">
    <div class="p-fluid">
        <div class="p-field">
            <span class="p-buttonset">
                <button
                    (click)="changePublishProperty()"
                    *ngIf="!published"
                    pButton
                    class="p-button-info"
                    pRipple
                    label="Објави изложбу"
                    icon="pi pi-upload"
                ></button>
                <button
                    (click)="changePublishProperty()"
                    *ngIf="published"
                    pButton
                    class="p-button-info"
                    pRipple
                    label="Повуци изложбу"
                    icon="pi pi-download"
                ></button>
                <button
                    (click)="saveExhibition()"
                    pButton
                    class="p-button-info"
                    pRipple
                    type="button"
                    label="Сачувај изложбу"
                    icon="pi pi-plus-circle"
                ></button>
                <button
                    (click)="deleteExhibition()"
                    pButton
                    class="p-button-info"
                    pRipple
                    type="button"
                    label="Избриши изложбу"
                    icon="pi pi-trash"
                ></button>
            </span>
        </div>
    </div>
</div>
