import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { PrimeNGConfig } from 'primeng/api';
import { Logout } from '../shared/states/auth/auth.state.actions';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    topbarTheme: string = 'pink';
    menuTheme: string = 'light';
    layoutMode: string = 'light';
    menuMode: string = 'static';
    inlineMenuPosition: string = 'bottom';
    inputStyle: string = 'filled';
    ripple: boolean = true;
    isRTL: boolean = false;

    constructor(private actions: Actions, private router: Router, private primengConfig: PrimeNGConfig) {}

    ngOnInit() {
        this.primengConfig.ripple = true;
        this.actions.pipe(ofActionDispatched(Logout)).subscribe(() => {
            this.router.navigate(['/login']);
        });
    }
}
