<div>
    <a label="Додај нову изложбу" (click)="addExhibition()" class="p-button-info p-mr-2 p-mb-2" pButton></a>
</div>

<div class="p-grid list-demo">
    <div class="p-col-12">
        <div class="card custom-color">
            <h5>Изложбе</h5>
            <p-dataView
                #dv
                [value]="exhibitions"
                [rows]="6"
                [totalRecords]="totalRecords"
                [lazy]="true"
                (onLazyLoad)="loadRecords($event)"
                [paginator]="true"
                [loading]="loading"
                [paginator]="true"
                filterBy="name"
                layout="grid"
            >
                <ng-template let-exhibition pTemplate="gridItem">
                    <div class="p-col-12 p-md-4">
                        <div
                            [ngStyle]="{
                                'background-color':
                                    exhibition['background_color'] !== null ? exhibition['background_color'] : 'transparent',
                                'background-image':
                                    exhibition['background_image_portrait'] !== null
                                        ? 'url(' + exhibition['background_image_portrait'].file + ')'
                                        : exhibition['background_image_landscape'] !== null
                                        ? 'url(' + exhibition['background_image_landscape'].file + ')'
                                        : 'none',
                                'background-size': 'cover'
                            }"
                            class="exhibition-grid-item card"
                        >
                            <div class="exhibition-grid-item-content">
                                <div class="imageContainer" [style]="{ height: imageProperties.height }">
                                    <img *ngIf="exhibition.thumbnail; else alternateImage" width="100%" [src]="exhibition.thumbnail.file" />
                                    <ng-template #alternateImage>
                                        <img width="100%" src="/assets/images/thumbnail/placeholder-thumbnail.png" />
                                    </ng-template>
                                </div>
                                <div class="exhibition-name">
                                    <p appScrollableText>
                                        {{ exhibition.title }}
                                    </p>
                                    <!--<div
                                        class="ck-content custom-content"
                                        appScrollableText
                                        [innerHTML]="exhibition.title | safeHtml"
                                    ></div>-->
                                </div>
                                <div class="exhibition-description">
                                    <p appScrollableText>
                                        {{ exhibition.subtitle }}
                                    </p>
                                    <!--<div
                                        class="ck-content custom-content"
                                        appScrollableText
                                        [innerHTML]="exhibition.subtitle | safeHtml"
                                    ></div>-->
                                </div>
                                <div class="car-buttons p-mt-5">
                                    <p-button
                                        (click)="edit(exhibition.id)"
                                        type="button"
                                        styleClass="p-button-info p-button-rounded p-mr-2"
                                        icon="pi pi-user-edit"
                                    ></p-button>
                                    <p-button
                                        (click)="view(exhibition.id)"
                                        type="button"
                                        styleClass="p-button-info p-button-rounded p-mr-2"
                                        icon="pi pi-eye"
                                    ></p-button>
                                    <p-button
                                        (click)="deleteExhibition(exhibition)"
                                        type="button"
                                        styleClass="p-button-info p-button-rounded p-mr-2"
                                        icon="pi pi-trash"
                                    ></p-button>
                                    <p-button
                                        (click)="changePublishProperty(exhibition)"
                                        *ngIf="exhibition.published"
                                        type="button"
                                        styleClass="p-button-info p-button-rounded"
                                        icon="pi pi-download"
                                    ></p-button>
                                    <p-button
                                        (click)="changePublishProperty(exhibition)"
                                        *ngIf="!exhibition.published"
                                        type="button"
                                        styleClass="p-button-info p-button-rounded"
                                        icon="pi pi-upload"
                                    ></p-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-dataView>
        </div>
    </div>
</div>

<p-confirmDialog #cdTwo [style]="{ width: '450px' }" [key]="'two-button-dialog'">
    <p-footer>
        <button type="button" pButton icon="pi pi-times" label="Не" (click)="cdTwo.reject()"></button>
        <button type="button" pButton icon="pi pi-check" label="Да" (click)="cdTwo.accept()"></button>
    </p-footer>
</p-confirmDialog>

<p-confirmDialog #cdThree [style]="{ width: '450px' }" [key]="'three-button-dialog'">
    <p-footer>
        <button type="button" pButton icon="pi pi-times" label="Откажи" (click)="cdThree.reject()"></button>
        <button type="button" pButton icon="pi pi-reply" label="Не" (click)="workWithLocalCopy()"></button>
        <button type="button" pButton icon="pi pi-check" label="Да" (click)="cdThree.accept()"></button>
    </p-footer>
</p-confirmDialog>

<p-confirmDialog #cdThree2 [style]="{ width: '450px' }" [key]="'three-button-dialog2'">
    <p-footer>
        <button type="button" pButton icon="pi pi-times" label="Откажи" (click)="cdThree2.reject()"></button>
        <button type="button" pButton icon="pi pi-reply" label="Не" (click)="workWithLocalCopyForView()"></button>
        <button type="button" pButton icon="pi pi-check" label="Да" (click)="cdThree2.accept()"></button>
    </p-footer>
</p-confirmDialog>
