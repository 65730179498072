import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreUiModule } from '../core/core-ui.module';
import { RouterModule } from '@angular/router';
import { StatistcsRoutes } from './statistics.routes';
import { StatisticsComponent } from './pages/statistics/statistics.component';
import { DownloadsCountryComponent } from './components/downloads-country/downloads-country.component';
import { DownloadsYearComponent } from './components/downloads-year/downloads-year.component';
import { VisitsMonthComponent } from './components/visits-month/visits-month.component';
import { DownloadsMonthComponent } from './components/downloads-month/downloads-month.component';
import { VisitsDownloadsComparedComponent } from './components/visits-downloads-compared/visits-downloads-compared.component';

@NgModule({
    declarations: [StatisticsComponent, DownloadsCountryComponent, DownloadsYearComponent, VisitsMonthComponent, DownloadsMonthComponent, VisitsDownloadsComparedComponent],
    imports: [CommonModule, CoreUiModule, RouterModule.forChild(StatistcsRoutes)],
    exports: []
})
export class StatisticsModule {}
