import { Component, Input, OnChanges } from '@angular/core';
import { pieChartOptions } from '../../config/pie-chart.options';
import { COLORS } from '../../constants/colors';
import { StatisticsService } from '../../services/statistics.service';
import { StatisticByCountry } from 'src/modules/shared/model/statistic-by-country';

@Component({
    selector: 'app-downloads-country',
    templateUrl: './downloads-country.component.html',
    styleUrls: ['./downloads-country.component.scss']
})
export class DownloadsCountryComponent implements OnChanges {
    @Input() public downloadByCountryStatistic: StatisticByCountry[];
    public pieChartOptions: any;
    public countryChartData: any;

    constructor(public statisticsService: StatisticsService) {
        this.pieChartOptions = pieChartOptions();
        this.countryChartData = {};
        this.downloadByCountryStatistic = [];
    }

    ngOnChanges(): void {
        this.initDownloadStatistic();
    }

    initDownloadStatistic(): void {
        const countries: string[] = [...new Set(this.downloadByCountryStatistic.map((item) => item.country))].sort();

        const pie: any = {
            data: [],
            backgroundColor: []
        };
        for (let i = 0; i < countries.length; i++) {
            const statisticForCountry: StatisticByCountry[] = this.downloadByCountryStatistic.filter(
                (item) => item.country === countries[i]
            );
            pie.data.push(statisticForCountry[0].total);
            pie.backgroundColor.push(COLORS[i]);
        }

        this.countryChartData = {
            labels: countries,
            datasets: [pie]
        };
    }
}
