import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { FileService } from '../../services/file.service';
import { File } from '../../../shared/model/file';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { UtilityService } from 'src/modules/shared/services/utility.service';
import { Paginator } from 'primeng/paginator';

@Component({
    selector: 'app-upload-dialog',
    templateUrl: './upload-dialog.component.html',
    styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnInit, OnDestroy {
    @ViewChild('paginator', { static: false }) public paginator!: Paginator;
    // File selected
    @Output() public fileSelected: EventEmitter<File>;
    @Output() public closeDialog: EventEmitter<void>;
    // Dropdown
    @Input() public selectionDisabled: boolean;
    @Input() public selectedOption: string;
    public selectOptions: any[];
    public accept: string;
    // Files
    public files: File[];
    public totalRecords: number;
    public loading: boolean;
    // Search input
    public search: string;
    public searchChanged: Subject<string>;
    // Image property
    public imageProperties: any;
    //Uploading progress.
    public uploading: boolean;
    public uploadProgress: number;
    //Youtube link
    public youtubeLink: string;

    constructor(public store: Store, public router: Router, public fileService: FileService, public utilityService: UtilityService) {
        this.fileSelected = new EventEmitter<File>();
        this.closeDialog = new EventEmitter<void>();

        this.selectedOption = 'image';
        this.accept = 'image/*';
        this.selectionDisabled = true;
        this.selectOptions = [
            { label: 'Видео фајлови', value: 'video' },
            { label: 'Аудио фајлови', value: 'audio' },
            { label: 'Слике', value: 'image' },
            { label: 'Фајлови за преузимање', value: 'download' }
        ];

        this.files = [];
        this.totalRecords = 0;
        this.loading = false;

        this.search = '';
        this.searchChanged = new Subject<string>();
        this.searchChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((searchInput) => {
            this.search = searchInput;
            this.paginator.changePage(0);
        });

        this.imageProperties = {
            height: '10px'
        };

        this.uploading = false;
        this.uploadProgress = 0;

        this.youtubeLink = '';
    }

    ngOnInit(): void {
        this.loadFiles(1);
    }

    ngOnDestroy(): void {
        this.searchChanged.unsubscribe();
    }

    loadFiles(page: number) {
        this.loading = true;
        this.fileService.getFiles(6, page, this.selectedOption, this.search).subscribe((files) => {
            if (this.selectedOption === 'image') {
                this.utilityService.findMaxHeightForImageDiv(files.results, this.imageProperties, 254);
            } else {
                this.imageProperties.height = '220px';
            }
            this.files = files.results;
            this.totalRecords = files.count;
            this.loading = false;
        });
    }

    fileTypeChanged(selectedOption: string) {
        this.selectedOption = selectedOption;
        if (this.selectedOption !== 'download') {
            this.accept = selectedOption + '/*';
        } else {
            this.accept = '';
        }
        this.search = '';
        this.paginator.changePage(0);
    }

    searchInputChanged(search: string) {
        this.searchChanged.next(search);
    }

    delete(fileId: number) {
        this.utilityService.displayConfirmationService('Да ли желите да обришете датотеку?', 'Брисање датотеке', () =>
            this.fileService.deleteFile(fileId).subscribe((ret) => {
                const page: number = this.paginator.getPage();
                this.utilityService.displayToastrMessage('Успешно сте избрисали датотеку!');
                if (this.totalRecords % 6 === 1 && page !== 0) {
                    this.paginator.changePage(page - 1);
                } else {
                    this.loadFiles(page + 1);
                }
            })
        );
    }

    select(file: File) {
        this.fileSelected.emit(file);
    }

    upload(uploadEvent: any) {
        const formData: FormData = new FormData();
        formData.append('file', uploadEvent.files[0]);
        formData.append('name', uploadEvent.files[0].name);
        formData.append('file_type', this.selectedOption);
        this.uploading = true;
        this.fileService.uploadFile(formData).subscribe((event: HttpEvent<any>) => {
            switch (event.type) {
                case HttpEventType.UploadProgress:
                    if (event.total) {
                        this.uploadProgress = Math.round((event.loaded / event.total) * 100);
                    }
                    break;
                case HttpEventType.Response:
                    this.utilityService.displayToastrMessage('Успешно слање датотеке', 'Успешно');
                    this.uploading = false;
                    this.uploadProgress = 0;
                    this.search = '';
                    this.paginator.changePage(0);
            }
        });
    }

    uploadYoutube(youtubeLink: string) {
        const formData: FormData = new FormData();
        formData.append('name', youtubeLink);
        formData.append('file_type', this.selectedOption);
        this.fileService.uploadFile(formData).subscribe((event: HttpEvent<any>) => {
            switch (event.type) {
                case HttpEventType.UploadProgress:
                    if (event.total) {
                        this.uploadProgress = Math.round((event.loaded / event.total) * 100);
                    }
                    break;
                case HttpEventType.Response:
                    this.utilityService.displayToastrMessage('Успешно слање видеа', 'Успешно');
                    this.uploading = false;
                    this.uploadProgress = 0;
                    this.search = '';
                    this.paginator.changePage(0);
            }
        });
    }

    paginate(event: any) {
        this.loadFiles(event.page + 1);
    }

    close() {
        this.closeDialog.emit();
    }

    getVideoId(youtubeUrl: string) {
        let retVal: RegExpMatchArray | null = null;
        const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        retVal = youtubeUrl.match(rx);
        if (retVal !== null) {
            return retVal[1];
        }
        return '';
    }
}
