export const barChartOptions = () => {
    const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
    const gridLinesColor = getComputedStyle(document.body).getPropertyValue('--divider-color') || 'rgba(160, 167, 181, .3)';
    const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
    return {
        legend: {
            display: true,
            labels: {
                fontFamily,
                fontColor: textColor
            }
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [
                {
                    ticks: {
                        fontFamily,
                        fontColor: textColor
                    },
                    gridLines: {
                        color: gridLinesColor
                    }
                }
            ],
            xAxes: [
                {
                    categoryPercentage: 0.9,
                    barPercentage: 0.8,
                    ticks: {
                        fontFamily,
                        fontColor: textColor
                    },
                    gridLines: {
                        color: gridLinesColor
                    }
                }
            ]
        },
        animation: {
            animateScale: true,
            animateRotate: true
        }
    };
};
