import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MainComponent } from './components/main/main.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreUiModule } from '../core/core-ui.module';
import { LoginComponent } from './pages/login/login.component';
import { NgxsModule } from '@ngxs/store';
import { AuthState } from '../shared/states/auth/auth.state';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { AuthInterceptor } from '../shared/interceptors/auth.interceptor';
import { AuthService } from '../shared/services/auth.service';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { LoginGuard } from '../shared/guards/login.guard';
import { ErrorInterceptor } from '../shared/interceptors/error.interceptor';
import { StatisticsModule } from '../statistics/statistics.module';
import { ContactModule } from '../contact/contact.module';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { ExhibitionModule } from '../exhibition/exhibition.module';
import { ExhibitionState } from '../shared/states/exhibition/exhibition.state';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LoadingSpinnerComponent } from './components/loading-spinner/loading-spinner.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PolicyComponent } from './pages/policy/policy.component';

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        NavbarComponent,
        FooterComponent,
        LoginComponent,
        AccessDeniedComponent,
        LoadingSpinnerComponent,
        NotFoundComponent,
        PolicyComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        CoreUiModule,
        SharedModule,
        NgxsModule.forRoot([AuthState, ExhibitionState], { developmentMode: true }),
        NgxsStoragePluginModule.forRoot({
            key: ['auth.token', 'auth.refreshToken', 'auth.email', 'exhibition']
        }),
        StatisticsModule,
        ContactModule,
        ExhibitionModule,
        ToastModule,
        ConfirmDialogModule,
        ProgressSpinnerModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        AuthService,
        AuthGuard,
        LoginGuard,
        ConfirmationService,
        MessageService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
