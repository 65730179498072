import { StateContext } from '@ngxs/store';
import { ExhibitionStateModel } from '../exhibition.state';
import { ChangeSelectedPage } from '../exhibition.state.actions';

export const changeSelectedPage = (ctx: StateContext<ExhibitionStateModel>, action: ChangeSelectedPage) => {
    ctx.patchState({
        selectedPage: action.payload.selectedPage,
        selectedSection: 0
    });
};
