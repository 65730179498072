import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {
    public loading$: Observable<boolean>;

    constructor(public store: Store) {
        this.loading$ = this.store.select((state) => state.exhibition.loading);
    }

    ngOnInit(): void {}
}
