import { Component, Input, OnChanges } from '@angular/core';
import { Statistic } from 'src/modules/shared/model/statistic';
import { pieChartOptions } from '../../config/pie-chart.options';
import { COLORS } from '../../constants/colors';
import { StatisticsService } from '../../services/statistics.service';

@Component({
    selector: 'app-downloads-year',
    templateUrl: './downloads-year.component.html',
    styleUrls: ['./downloads-year.component.scss']
})
export class DownloadsYearComponent implements OnChanges {
    @Input() public downloadStatistic: Statistic[];
    public pieChartOptions: any;
    public yearlyChartData: any;

    constructor(public statisticsService: StatisticsService) {
        this.pieChartOptions = pieChartOptions();
        this.yearlyChartData = {};
        this.downloadStatistic = [];
    }

    ngOnChanges(): void {
        this.initDownloadStatistic();
    }

    initDownloadStatistic(): void {
        const years: number[] = [...new Set(this.downloadStatistic.map((item) => item.year))].sort();

        const pie: any = {
            data: [],
            backgroundColor: []
        };
        for (let i = 0; i < years.length; i++) {
            const statisticForYearSum: Statistic[] = this.downloadStatistic.filter((item) => item.year === years[i]);
            pie.data.push(statisticForYearSum.reduce((accum, item) => accum + item.total, 0));
            pie.backgroundColor.push(COLORS[i]);
        }

        this.yearlyChartData = {
            labels: years,
            datasets: [pie]
        };
    }
}
