import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginPayload } from '../model/login-payload';
import { Login } from '../model/login';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(private http: HttpClient) {}

    login(credentials: Login): Observable<LoginPayload> {
        return this.http.post<LoginPayload>('api/token/', credentials, { headers: this.headers });
    }

    refreshToken(refreshToken: string): Observable<LoginPayload> {
        return this.http.post<LoginPayload>('api/token/refresh/', { refresh: refreshToken }, { headers: this.headers });
    }
}
