import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sort'
})
export class SortPipe implements PipeTransform {
    transform(value: any[], column: string = ''): any[] {
        if (!value) {
            return value;
        } // no array
        if (value.length <= 1) {
            return value;
        } // array with only one item
        return value.sort((item) => item[column]);
    }
}
