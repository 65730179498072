import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { Subscription } from 'rxjs';
import { Page } from 'src/modules/shared/model/page';
import { ExhibitionState } from 'src/modules/shared/states/exhibition/exhibition.state';
import { Section } from '../../../shared/model/section';
import {
    ChangeEditMode,
    ChangePageRowNumber,
    ChangePageColumnNumber,
    RemoveContentFromAllSections,
    RemoveSection,
    RemoveSectionContent,
    ChangeSelectedSection,
    ChangeSectionContent,
    ChangeSectionOrder
} from '../../../shared/states/exhibition/exhibition.state.actions';
import { MenuItem } from 'primeng/api';
import { File } from '../../../shared/model/file';
import { UtilityService } from 'src/modules/shared/services/utility.service';
import { FileService } from '../../services/file.service';
import * as _ from 'lodash';

@Component({
    selector: 'app-page-details',
    templateUrl: './page-details.component.html',
    styleUrls: ['./page-details.component.scss']
})
export class PageDetailsComponent implements OnInit, OnDestroy {
    @Output() addSectionEvent: EventEmitter<number>;

    public uploadFileDialog: boolean;
    public sectionBackgroundDialog: boolean;

    public selectedSectionSubscription!: Subscription;
    public selectedSection: number;
    public selectedSectionType: any;

    public editable: boolean;
    public rowNumber: number;
    public columnNumber: number;

    public pageSubscription!: Subscription;
    public page: Page | undefined;
    public editableSubscription!: Subscription;

    public menuItems: MenuItem[];

    constructor(public store: Store, public utilityService: UtilityService, public fileservice: FileService) {
        this.addSectionEvent = new EventEmitter<number>();

        this.uploadFileDialog = false;
        this.sectionBackgroundDialog = false;

        this.selectedSection = 0;
        this.selectedSectionType = '';

        this.editable = this.store.selectSnapshot(ExhibitionState.editable);
        this.rowNumber = 0;
        this.columnNumber = 0;

        this.menuItems = [];
    }

    ngOnInit(): void {
        this.selectedSectionSubscription = this.store
            .select((state) => state.exhibition.selectedSection)
            .subscribe((selectedSection: number) => {
                this.selectedSection = selectedSection;
                this.selectedSectionType = this.store.selectSnapshot(ExhibitionState.selectedSectionType);
                this.menuItems = this.generateContextMenuItems(this.page?.sections);
            });
        this.pageSubscription = this.store.select(ExhibitionState.page).subscribe((page: Page | undefined) => {
            this.menuItems = this.generateContextMenuItems(page?.sections);
            this.page = page;
        });
        this.editableSubscription = this.store
            .select((state) => state.exhibition.editable)
            .subscribe((editable: boolean) => {
                this.editable = editable;
            });
    }

    ngOnDestroy(): void {
        this.selectedSectionSubscription.unsubscribe();
        this.pageSubscription.unsubscribe();
    }

    openUploadDialog() {
        this.uploadFileDialog = true;
    }

    closeUploadDialog() {
        this.uploadFileDialog = false;
    }

    openSectionBackgroundDialog() {
        this.sectionBackgroundDialog = true;
    }

    closeSectionBackgroundDialog() {
        this.sectionBackgroundDialog = false;
    }

    generateContextMenuItems(sections: Section[] | undefined) {
        const menuItems: MenuItem[] = [];
        if (sections === undefined) {
            return menuItems;
        }
        sections = _.cloneDeep(sections);
        for (const section of sections.sort((sec1: Section, sec2: Section) => sec1.section_order - sec2.section_order)) {
            const icon: string = section.section_order === this.selectedSection ? 'pi pi-circle-on' : 'pi pi-circle-off';
            menuItems.push({
                label: section.section_order.toString(),
                icon,
                command: () => {
                    this.changeSectionOrder(section.section_order);
                }
            });
        }
        return menuItems;
    }

    openContextMenu(event: any, cm: any) {
        event.preventDefault();
        event.stopPropagation();
        cm.show(event);
        return false;
    }

    updateEditable(editable: boolean): void {
        this.store.dispatch(new ChangeEditMode({ editable }));
        this.store.dispatch(new ChangeSelectedSection({ selectedSection: 0 }));
        this.editable = editable;
    }

    rowChange(rowNumber: number, page: Page) {
        if (rowNumber < 10) {
            this.utilityService.displayToastrMessage('Број редова не може бити мањи од 10!', 'Грешка', 'error');
        } else if (rowNumber > 60) {
            this.utilityService.displayToastrMessage('Број редова не може бити већи од 60!', 'Грешка', 'error');
        } else {
            const maxBottomCoordinate = Math.max.apply(
                Math,
                page.sections.map((section: Section) => section.y_coordinate - 1 + section.height)
            );
            if (rowNumber < maxBottomCoordinate) {
                this.utilityService.displayToastrMessage('Број редова не може бити мањи од максималне Y координате!', 'Грешка', 'error');
            } else {
                this.store.dispatch(new ChangePageRowNumber({ rowNumber }));
            }
        }
    }

    columnChange(columnNumber: number, page: Page) {
        if (columnNumber < 10) {
            this.utilityService.displayToastrMessage('Број колона не може бити мањи од 10!', 'Грешка', 'error');
        } else if (columnNumber > 60) {
            this.utilityService.displayToastrMessage('Број колона не може бити већи од 60!', 'Грешка', 'error');
        } else {
            const maxRightCoordinate = Math.max.apply(
                Math,
                page.sections.map((section: Section) => section.x_coordinate - 1 + section.width)
            );
            if (columnNumber < maxRightCoordinate) {
                this.utilityService.displayToastrMessage('Број колона не може бити мањи од максималне X координате!', 'Грешка', 'error');
            } else {
                this.store.dispatch(new ChangePageColumnNumber({ columnNumber }));
            }
        }
    }

    addSection(sectionOrder: number) {
        this.addSectionEvent.emit(sectionOrder);
    }

    deleteAllSectionContent(sections: Section[]) {
        this.utilityService.displayConfirmationService(
            'Да ли желите да обришете садржај свих секција?',
            'Брисање садржаја свих секција',
            () => {
                this.store.dispatch(new RemoveContentFromAllSections({ sections }));
            }
        );
    }

    deleteSection(sections: Section[]) {
        this.utilityService.displayConfirmationService('Да ли желите да обришете означену секцију?', 'Брисање означене секције', () => {
            this.store.dispatch(new RemoveSection({ sections }));
        });
    }

    deleteSectionContent(sections: Section[]) {
        this.utilityService.displayConfirmationService(
            'Да ли желите да обришете садржај означене секције?',
            'Брисање садржаја означене секције',
            () => {
                this.store.dispatch(new RemoveSectionContent({ sections }));
            }
        );
    }

    addTextContent() {
        this.store.dispatch(
            new ChangeSectionContent({
                content: {
                    content_type: 'text',
                    text_content: '<p>Унесите неки текст</p>',
                    file: null
                }
            })
        );
    }

    changeSectionContent(fileSelected: File) {
        this.closeUploadDialog();
        this.store.dispatch(
            new ChangeSectionContent({
                content: {
                    content_type: fileSelected.file_type,
                    text_content: null,
                    file: fileSelected
                }
            })
        );
    }

    changeSectionOrder(new_section_order: number) {
        this.store.dispatch(new ChangeSectionOrder({ new_section_order }));
    }
}
