<div class="pages-body contact-page p-d-flex p-flex-column">
    <div class="map">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2808.692381476689!2d19.842076115577417!3d45.254012955464596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475b1069a18e8141%3A0x23c8bde29e970de4!2sKralja%20Aleksandra%205%2C%20Novi%20Sad%2021000!5e0!3m2!1sen!2srs!4v1613036003529!5m2!1sen!2srs"
            aria-hidden="false"
            tabindex="0"
            allowtransparency="true"
        ></iframe>
        <span></span>
    </div>

    <form [formGroup]="contactForm">
        <div class="pages-panel">
            <div class="card p-grid p-shadow-12 custom-color-form">
                <div class="left-panel p-lg-6 p-md-6 p-sm-12">
                    <h4 class="p-mb-3">Контактирајте нас</h4>

                    <input
                        aria-describedby="nameAndSurname-required"
                        id="nameAndSurname"
                        formControlName="nameAndSurname"
                        type="text"
                        class="contact-input p-mb-3"
                        placeholder="Ваше име и презиме"
                        pInputText
                    />
                    <small
                        *ngIf="contactForm.controls.nameAndSurname.touched && contactForm.controls.nameAndSurname.errors?.required"
                        id="nameAndSurname-required"
                        class="p-error"
                        >Име и презиме су обавезни</small
                    >
                    <small
                        *ngIf="contactForm.controls.nameAndSurname.touched && contactForm.controls.nameAndSurname.errors?.maxlength"
                        id="nameAndSurname-maxlength"
                        class="p-error"
                        >Име и презиме не смеју бити дужи од 80 карактера</small
                    >

                    <input
                        aria-describedby="nameAndSurname-required nameAndSurname-email"
                        id="email"
                        formControlName="email"
                        type="text"
                        class="contact-input p-mb-3"
                        placeholder="Ваша емаил адреса"
                        pInputText
                    />
                    <small
                        *ngIf="contactForm.controls.email.touched && contactForm.controls.email.errors?.required"
                        id="email-required"
                        class="p-error"
                        >Емаил адреса је обавезна</small
                    >
                    <small
                        *ngIf="contactForm.controls.email.touched && contactForm.controls.email.errors?.email"
                        id="email-email"
                        class="p-error"
                        >Емаил адреса није у валидном формату</small
                    >

                    <textarea
                        aria-describedby="message-required message-maxLength"
                        id="message"
                        formControlName="message"
                        class="contact-input contact-message p-mb-6"
                        rows="10"
                        placeholder="Ваша порука"
                        pInputTextarea
                    ></textarea>
                    <small
                        *ngIf="contactForm.controls.message.touched && contactForm.controls.message.errors?.required"
                        id="message-required"
                        class="p-error"
                        >Порука је обавезна</small
                    >
                    <small
                        *ngIf="contactForm.controls.message.touched && contactForm.controls.message.errors?.maxlength"
                        id="message-maxLenght"
                        class="p-error"
                        >Порука не може бити дужа од 500 карактера</small
                    ><br /><br />

                    <button
                        [disabled]="contactForm.invalid"
                        (click)="sendInformation()"
                        class="p-button-info p-mb-3"
                        label="Пошаљите поруку"
                        pButton
                        pRipple
                    ></button>
                </div>
                <div class="right-panel p-lg-6 p-md-6 p-sm-12 p-mx-auto">
                    <div class="p-grid">
                        <div class="p-md-offset-3 p-md-6 p-md-offset-3 p-sm-offset-2 p-sm-8 p-sm-offset-2">
                            <i class="pi pi-home p-mb-2 p-mt-5"></i>
                            <p class="title p-my-3">Позоришни Музеј Војводине</p>
                            <p>Краља Александра 5, 21000 Нови Сад</p>

                            <i class="pi pi-user p-mb-2 p-mt-5"></i>
                            <p class="title p-my-3">Директор</p>
                            <p>Миодраг Кајтез</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
