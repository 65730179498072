import { StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import * as _ from 'lodash';
import { ExhibitionStateModel } from '../exhibition.state';
import { ChangePageBackgroundForAll } from '../exhibition.state.actions';

export const changePageBackgroundForAll = (ctx: StateContext<ExhibitionStateModel>, action: ChangePageBackgroundForAll) => {
    const state = ctx.getState();
    const pages = _.cloneDeep(state.pages).map((page) => ({
        ...page,
        background_color: action.payload.backgroundColor,
        background_image_portrait: action.payload.backgroundImagePortrait,
        background_image_landscape: action.payload.backgroundImageLandscape
    }));
    ctx.setState(
        patch({
            pages
        })
    );
};
