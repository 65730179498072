<p-accordionTab [selected]="true" header="Управљање Страницом" style="margin-bottom: 0; background: red">
    <div *ngIf="languages.length !== 0 && templates.length !== 0; else loading">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
                <span class="p-float-label">
                    <p-dropdown
                        [options]="languages"
                        [autoDisplayFirst]="true"
                        [ngModel]="selectedLanguage$ | async"
                        (ngModelChange)="changeExhibitionLanguage($event)"
                        name="selectedPageLanguage"
                    ></p-dropdown>
                    <label>Језик изложбе</label>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <span class="p-float-label">
                    <p-dropdown
                        #drop
                        [options]="templates"
                        [ngModel]="selectedTemplateForApplying"
                        (ngModelChange)="applyTemplate($event)"
                        [autoDisplayFirst]="false"
                        name="selectedLayout"
                    ></p-dropdown>
                    <label>Шаблон</label>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-12">
                <span class="p-buttonset">
                    <button
                        (click)="addPage(pages)"
                        class="p-button-info"
                        label="Додај нову страницу"
                        icon="pi pi-plus-circle"
                        pButton
                        pRipple
                    ></button>
                    <button
                        [disabled]="pages.length === 0"
                        (click)="removePage()"
                        class="p-button-info"
                        type="button"
                        label="Избриши страницу"
                        icon="pi pi-trash"
                        pButton
                        pRipple
                    ></button>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-12">
                <p-listbox
                    [style]="{ width: '100%' }"
                    [listStyle]="{ 'max-height': '115px', 'min-height': '115px' }"
                    [options]="pages"
                    [ngModel]="selectedPage$ | async"
                    [filter]="false"
                    (ngModelChange)="changePage($event)"
                >
                </p-listbox>
            </div>
        </div>
    </div>
    <div *ngIf="page$ | async as page; else loadingPage">
        <div class="p-fluid p-formgrid p-grid">
            <div *ngIf="page.gallery === false" class="p-field p-col-12 p-md-6">
                <button
                    (click)="convertToGallery(page.sections)"
                    class="p-button-info"
                    type="button"
                    label="Направи галерију"
                    icon="pi pi-microsoft"
                    pButton
                    pRipple
                ></button>
            </div>
            <div *ngIf="page.gallery === true" class="p-field p-col-12 p-md-6">
                <button
                    (click)="revertFromGallery()"
                    class="p-button-info"
                    type="button"
                    label="Конвертуј у обичну страницу"
                    icon="pi pi-book"
                    pButton
                    pRipple
                ></button>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <button
                    [disabled]="page.gallery === false"
                    (click)="openGalleryManagementDialog(page.sections)"
                    class="p-button-info"
                    type="button"
                    label="Управљај галеријом"
                    icon="pi pi-plus-circle"
                    pButton
                    pRipple
                ></button>
            </div>
            <div class="p-field p-col-12 p-md-12">
                <span class="p-float-label">
                    <p-dropdown
                        [(ngModel)]="selectedItemForOrderChange"
                        [options]="pages"
                        [autoDisplayFirst]="false"
                        (onChange)="changePageOrder($event)"
                    ></p-dropdown>
                    <label>Промена редоследа означене странице</label>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-12">
                <button
                    [disabled]="pages.length === 0"
                    (click)="openBackgroundDialog()"
                    class="p-button-info"
                    type="button"
                    label="Додај позадину страници"
                    icon="pi pi-plus-circle"
                    pButton
                    pRipple
                ></button>
            </div>
            <div class="p-field p-col-12 p-md-3" style="text-align: center">
                <label>Насловна</label>
                <span class="p-float-label">
                    <p-inputSwitch
                        [ngModel]="page.introductory"
                        (ngModelChange)="makePageIntroductory($event)"
                        id="editableSwitch"
                    ></p-inputSwitch>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-9">
                <span class="p-float-label">
                    <p-dropdown
                        [autoDisplayFirst]="false"
                        [options]="languages"
                        [ngModel]="page.language"
                        (ngModelChange)="changePageLanguage($event)"
                        name="selectedPageLanguage"
                    ></p-dropdown>
                    <label>Jезик странице</label>
                </span>
            </div>
        </div>
    </div>
    <ng-template #loading>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <p-skeleton height="39px"></p-skeleton>
            </div>
        </div>
        <div class="p-fluid">
            <div class="p-field">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field">
                <p-skeleton height="115px"></p-skeleton>
            </div>
        </div>
    </ng-template>
    <ng-template #loadingPage>
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-6">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-6">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-12">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-12">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-9">
                <p-skeleton height="39px"></p-skeleton>
            </div>
        </div>
    </ng-template>
</p-accordionTab>

<app-gallery-management
    [imagesInGallery]="imagesInGallery"
    (imagesForGallery)="imagesForGallery($event)"
    *ngIf="galleryManagementDialog"
    (closeDialog)="closeGalleryManagementDialog()"
></app-gallery-management>

<app-background-dialog
    *ngIf="backgroundDialog"
    [backgroundImageFor]="'page'"
    (closeDialog)="closeBackgroundDialog()"
></app-background-dialog>
