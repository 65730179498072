import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Exhibition } from 'src/modules/shared/model/exhibition';
import { PaginatedResponse } from '../../shared/model/paginated-response';
import { Language } from '../../shared/model/language';
import { TemplatePage } from '../../shared/model/template-page';

@Injectable({
    providedIn: 'root'
})
export class ExhibitionService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(public http: HttpClient) {}

    getExhibitions(pageSize: number, page: number): Observable<PaginatedResponse<Exhibition>> {
        return this.http.get<PaginatedResponse<Exhibition>>(`api/exhibition/?page=${page}&page_size=${pageSize}&ordering=-create_date`, {
            headers: this.headers
        });
    }

    getExhibitionDetail(exhibitionId: number): Observable<Exhibition> {
        return this.http.get<Exhibition>(`api/exhibition/${exhibitionId}`);
    }

    deleteExhibition(exhibitionId: number | undefined): Observable<any> {
        return this.http.delete(`api/exhibition/${exhibitionId}`);
    }

    publishingExhibition(exhibitionId: number | undefined, published: boolean): Observable<any> {
        return this.http.put(`api/exhibition/${exhibitionId}/publishing/`, { id: exhibitionId, published }, { headers: this.headers });
    }

    getLanguages(): Observable<Language[]> {
        return this.http.get<Language[]>('api/language/', { headers: this.headers });
    }

    getTemplatePages(): Observable<TemplatePage[]> {
        return this.http.get<TemplatePage[]>('api/template/', { headers: this.headers });
    }

    saveExhibition(exhibition: Exhibition): Observable<any> {
        return this.http.post('api/exhibition/save/', exhibition, { headers: this.headers });
    }

    getPublishedIntroductioryPagesForExhibition(): Observable<Exhibition[]> {
        return this.http.get<Exhibition[]>('api/exhibition/preview/', { headers: this.headers });
    }
}
