import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-policy',
    templateUrl: './policy.component.html',
    styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {
    public text: string = '';
    private language: string | null = 'en';

    constructor(private router: ActivatedRoute) {}

    ngOnInit() {
        this.language = this.router.snapshot.paramMap.get('language');
        if (this.language === 'en') {
            this.text =
                'Application can collect user data about the country in which the user ' +
                'is located if the user allows it when opening the applicaiton';
        } else {
            this.text =
                'Aplikacija može da prikuplja korisničke podatke o državi u kojoj ' +
                'se nalazi ukoliko korisnik to dozvoli pri pokretanju aplikacije';
        }
    }
}
