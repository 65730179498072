import { StateContext } from '@ngxs/store';
import * as _ from 'lodash';
import { Page } from 'src/modules/shared/model/page';
import { ExhibitionStateModel } from '../exhibition.state';
import { ChangePageLanguage } from '../exhibition.state.actions';

export const changePageLanguage = (ctx: StateContext<ExhibitionStateModel>, action: ChangePageLanguage) => {
    const state = ctx.getState();
    const pagesToIgnore: Page[] = _.cloneDeep(state.pages).filter((page) => page.language !== state.selectedLanguage);
    const pagesToReorder: Page[] = _.cloneDeep(state.pages)
        .filter((page) => page.language === state.selectedLanguage)
        .sort((page1: Page, page2: Page) => {
            if (page1.page_number > page2.page_number) {
                return 1;
            }
            if (page1.page_number < page2.page_number) {
                return -1;
            }
            return 0;
        });
    const new_page_number: number = pagesToReorder.length;
    let page_number: number = 1;
    for (const page of pagesToReorder) {
        if (page.page_number === state.selectedPage) {
            page.language = action.payload.pageLanguage;
            page.page_number = new_page_number + 1;
            page.introductory = false;
        } else {
            page.page_number = page_number;
            page_number += 1;
        }
    }
    const pages = [...pagesToIgnore, ...pagesToReorder];
    ctx.patchState({
        selectedLanguage: action.payload.pageLanguage,
        selectedPage: new_page_number + 1,
        pages
    });
};
