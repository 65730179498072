import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Exhibition } from 'src/modules/shared/model/exhibition';
import { ExhibitionService } from '../../services/exhibition.service';
import { ConfirmationService, LazyLoadEvent } from 'primeng/api';
import { Store } from '@ngxs/store';
import { LoadExhibition, NewExhibition } from 'src/modules/shared/states/exhibition/exhibition.state.actions';
import { UtilityService } from 'src/modules/shared/services/utility.service';

@Component({
    selector: 'app-exhibition-list',
    templateUrl: './exhibition-list.component.html',
    styleUrls: ['./exhibition-list.component.scss']
})
export class ExhibitionListComponent implements OnInit {
    public exhibitions: Exhibition[];
    public totalRecords: number;
    public loading: boolean;
    public imageProperties: any;

    constructor(
        public store: Store,
        public exhibitionService: ExhibitionService,
        public confirmationService: ConfirmationService,
        public utilityService: UtilityService,
        public router: Router
    ) {
        this.exhibitions = [];
        this.loading = false;
        this.totalRecords = 0;
        this.imageProperties = {
            height: '10px'
        };
    }

    ngOnInit(): void {
        this.loading = true;
    }

    addExhibition(): void {
        const exhibition: Exhibition = this.utilityService.createBlankExhibition();
        this.utilityService.displayConfirmationService(
            'Да ли желите да креирате нову изложбу? (Несачуване промене биће изгубљене!)',
            'Додавање нове изложбе',
            () => {
                this.store.dispatch(new NewExhibition({ exhibition }));
                this.router.navigate(['/pozorisni-muzej/izlozbe/dodaj']);
            }
        );
    }

    deleteExhibition(exhibition: Exhibition): void {
        this.utilityService.displayConfirmationService('Да ли сте сигурни да желите да обришете изложбу?', 'Брисање изложбе', () => {
            this.exhibitionService.deleteExhibition(exhibition.id).subscribe(() => {
                this.utilityService.displayToastrMessage('Успешно обрисана изложба');
                this.loadRecords({ first: 0, rows: 6 });
            });
        });
    }

    changePublishProperty(exhibition: any): void {
        const dialogMessage = exhibition.published ? 'Да ли желите на повучете изложбу?' : 'Да ли желите да објавите изложбу?';
        const header = exhibition.published ? 'Повлачење изложбе' : 'Објављивање изложбе';
        const toastMessage = exhibition.published ? 'Успешно повучена изложба' : 'Успешно објављена изложба';

        this.utilityService.displayConfirmationService(dialogMessage, header, () => {
            this.exhibitionService.publishingExhibition(exhibition.id, !exhibition.published).subscribe((ret) => {
                this.utilityService.displayToastrMessage(toastMessage);
                exhibition.published = !exhibition.published;
            });
        });
    }

    edit(id: number): void {
        const loadingId = this.store.selectSnapshot((state) => state.exhibition.id);
        if (loadingId !== id) {
            this.confirmationService.confirm({
                key: 'two-button-dialog',
                message: 'Да ли желите да учитате нову изложбу са сервера? (Претходно несачуван садржај биће изгубљен)!',
                header: 'Учитавање нове изложбе',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.store.dispatch(new LoadExhibition({ id })).subscribe((ret) => {
                        this.router.navigate([`/pozorisni-muzej/izlozbe/izmena/${id}`]);
                    });
                }
            });
        } else {
            this.confirmationService.confirm({
                key: 'three-button-dialog',
                message: 'Да ли желите да повучете промене са сервера? (Претходно несачуван садржај биће изгубљен)!',
                header: 'Повлачење нових промена',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.store.dispatch(new LoadExhibition({ id })).subscribe((ret) => {
                        this.router.navigate([`/pozorisni-muzej/izlozbe/izmena/${id}`]);
                    });
                }
            });
        }
    }

    view(id: number): void {
        const loadingId = this.store.selectSnapshot((state) => state.exhibition.id);
        if (loadingId !== id) {
            this.confirmationService.confirm({
                key: 'two-button-dialog',
                message: 'Да ли желите да учитате нову изложбу са сервера ради прегледа? (Претходно несачуван садржај биће изгубљен)!',
                header: 'Учитавање нове изложбе',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.store.dispatch(new LoadExhibition({ id })).subscribe((ret) => {
                        this.router.navigate([`/pozorisni-muzej/izlozbe/pregled/${id}`]);
                    });
                }
            });
        } else {
            this.confirmationService.confirm({
                key: 'three-button-dialog2',
                message: 'Да ли желите да повучете промене са сервера ради прегледа? (Претходно несачуван садржај биће изгубљен)!',
                header: 'Повлачење нових промена',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    this.store.dispatch(new LoadExhibition({ id })).subscribe((ret) => {
                        this.router.navigate([`/pozorisni-muzej/izlozbe/pregled/${id}`]);
                    });
                }
            });
        }
    }

    workWithLocalCopy() {
        const id = this.store.selectSnapshot((state) => state.exhibition.id);
        this.router.navigate([`/pozorisni-muzej/izlozbe/izmena/${id}`]);
    }

    workWithLocalCopyForView() {
        const id = this.store.selectSnapshot((state) => state.exhibition.id);
        this.router.navigate([`/pozorisni-muzej/izlozbe/pregled/${id}`]);
    }

    loadRecords(event: LazyLoadEvent) {
        this.loading = true;
        const page: number = event.first === undefined ? 1 : event.first / 6;
        this.exhibitionService.getExhibitions(6, page + 1).subscribe((ret) => {
            this.utilityService.findMaxHeightForImageDiv(
                ret.results.map((exibition) => exibition.thumbnail),
                this.imageProperties,
                400
            );
            this.exhibitions = ret.results;
            this.totalRecords = ret.count;
            this.loading = false;
        });
    }
}
