import { StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { Page } from 'src/modules/shared/model/page';
import { Section } from 'src/modules/shared/model/section';
import { ExhibitionStateModel } from '../exhibition.state';
import { ChangeSectionCoordinates } from '../exhibition.state.actions';

export const changeSectionCoordinates = (ctx: StateContext<ExhibitionStateModel>, action: ChangeSectionCoordinates) => {
    const state = ctx.getState();
    ctx.setState(
        patch({
            pages: updateItem<Page>(
                (item) => item?.page_number === state.selectedPage && item?.language === state.selectedLanguage,
                patch({
                    sections: updateItem<Section>(
                        (item) => item?.section_order === action.payload.section_order,
                        patch({
                            x_coordinate: action.payload.left,
                            y_coordinate: action.payload.top,
                            width: action.payload.width,
                            height: action.payload.height
                        })
                    )
                })
            )
        })
    );
};
