<div class="card content" style="background-color: #fae7cc">
    <ng-container *ngIf="page">
        <h1 class="p-d-flex p-jc-center">
            {{ title }}
            <!--<div class="ck-content custom-content" appScrollableText [innerHTML]="title | safeHtml"></div>-->
        </h1>
        <h3 class="p-d-flex p-jc-center">
            {{ subtitle }}
            <!--<div class="ck-content custom-content" appScrollableText [innerHTML]="subtitle | safeHtml"></div>-->
        </h3>

        <div *ngIf="page.gallery === false" class="dashboard-container">
            <ngx-widget-grid
                #grid
                [ngStyle]="{
                    'background-color': page['background_color'] !== null ? page['background_color'] : '#FAE7CC',
                    'background-image':
                        page['background_image_landscape'] !== null ? 'url(' + page['background_image_landscape'].file + ')' : 'none',
                    'background-size': 'cover'
                }"
                [rows]="page.row_number"
                [columns]="page.column_number"
                [showGrid]="false"
            >
                <ng-container *ngFor="let section of sections; let i = index">
                    <ngx-widget
                        class="ngxWidgetNoBorder"
                        [position]="section.position"
                        [ngStyle]="{
                            'background-color': 'transparent',
                            'background-image':
                                section['background_image_portrait'] !== null
                                    ? 'url(' + section['background_image_portrait'].file + ')'
                                    : section['background_image_landscape'] !== null
                                    ? 'url(' + section['background_image_landscape'].file + ')'
                                    : 'none',
                            'background-size': 'contain'
                        }"
                    >
                        <img class="center" *ngIf="section.content?.content_type === 'image'" [src]="section.content?.file?.file" />
                        <audio *ngIf="section.content?.content_type === 'audio'" controls>
                            <source [src]="section.content?.file?.file" />
                        </audio>
                        <div *ngIf="section.content?.content_type === 'video'" #youtubeWrapper style="width: 100%; height: 100%">
                            <youtube-player class="content" [videoId]="getVideoId(section.content?.file?.name)" suggestedQuality="highres">
                            </youtube-player>
                        </div>
                        <div class="download" *ngIf="section.content?.content_type === 'download'">
                            <a [href]="section.content?.file?.file">
                                <img class="centerDownload" src="/assets/images/icons/download-icon.png" />
                            </a>
                        </div>
                        <div *ngIf="section.content?.content_type === 'text'">
                            <div class="ck-content custom-content" [innerHTML]="section.content.text_content | safeHtml"></div>
                        </div>
                    </ngx-widget>
                </ng-container>
            </ngx-widget-grid>
        </div>
        <div *ngIf="page.gallery === true" class="gallery">
            <ngx-masonry [options]="{ gutter: 10 }" [ordered]="true">
                <div ngxMasonryItem class="masonry-item" *ngFor="let item of getSectionInOrderForGaller(page.sections)">
                    <img class="galleryImage" [src]="item.content?.file?.file" />
                </div>
            </ngx-masonry>
        </div>
    </ng-container>
</div>
