<p-dialog
    [draggable]="false"
    [style]="{ width: '40vw' }"
    [(visible)]="visible"
    (onHide)="hide()"
    header="Одговорите на поруку"
    icon="pi pi-send"
>
    <form [formGroup]="responseForm">
        <div class="p-fluid">
            <div class="p-field">
                <input
                    aria-describedby="email-required"
                    id="email"
                    type="text"
                    formControlName="email"
                    class="contact-input"
                    placeholder="Емаил адреса"
                    pInputText
                />
                <small
                    *ngIf="responseForm.controls.email.touched && responseForm.controls.email.errors?.required"
                    id="email-required"
                    class="p-error"
                    >Емаил адреса је обавезна</small
                >
                <small
                    *ngIf="responseForm.controls.email.touched && responseForm.controls.email.errors?.email"
                    id="email-email"
                    class="p-error"
                    >Емаил адреса није у валидном формату</small
                >

                <input
                    aria-describedby="title-required"
                    id="title"
                    type="text"
                    formControlName="title"
                    class="contact-input"
                    placeholder="Наслов"
                    pInputText
                />
                <small
                    *ngIf="responseForm.controls.title.touched && responseForm.controls.title.errors?.required"
                    id="nameAndSurname-required"
                    class="p-error"
                    >Наслов је обавезан</small
                >
                <small
                    *ngIf="responseForm.controls.title.touched && responseForm.controls.title.errors?.maxlength"
                    id="message-maxLenght"
                    class="p-error"
                    >Наслов не може бити дужи од 100 карактера</small
                >

                <textarea
                    aria-describedby="message-required message-maxLength"
                    id="message"
                    class="contact-input contact-message"
                    rows="10"
                    placeholder="Ваша порука"
                    formControlName="message"
                    pInputTextarea
                ></textarea>
                <small
                    *ngIf="responseForm.controls.message.touched && responseForm.controls.message.errors?.required"
                    id="message-required"
                    class="p-error"
                    >Порука је обавезна</small
                >
                <small
                    *ngIf="responseForm.controls.message.touched && responseForm.controls.message.errors?.maxlength"
                    id="message-maxLenght"
                    class="p-error"
                    >Порука не може бити дужа од 1000 карактера</small
                >

                <span class="p-buttonset">
                    <button
                        [disabled]="responseForm.invalid"
                        (click)="sendResponse()"
                        type="button"
                        label="Пошаљи"
                        class="p-button-info"
                        pButton
                    ></button>
                    <button (click)="hide()" type="button" label="Откажи" class="p-button-info" pButton></button>
                </span>
            </div>
        </div>
    </form>
</p-dialog>
