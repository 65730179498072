import { StateContext } from '@ngxs/store';
import { ExhibitionStateModel } from '../exhibition.state';
import { ChangeSelectedLanguage } from '../exhibition.state.actions';

export const changeSelectedLanguage = (ctx: StateContext<ExhibitionStateModel>, action: ChangeSelectedLanguage) => {
    ctx.patchState({
        selectedPage: 1,
        selectedLanguage: action.payload.selectedLanguage,
        selectedSection: 0
    });
};
