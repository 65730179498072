import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactComponent } from './pages/contact/contact.component';
import { CoreUiModule } from '../core/core-ui.module';
import { RouterModule } from '@angular/router';
import { ContactRoutes } from './contact.routes';
import { MessageListComponent } from './pages/message-list/message-list.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ContactService } from './services/contact.service';
import { SendEmailDialogComponent } from './components/send-email-dialog/send-email-dialog.component';

@NgModule({
    declarations: [ContactComponent, MessageListComponent, SendEmailDialogComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, CoreUiModule, RouterModule.forChild(ContactRoutes)],
    providers: [ContactService]
})
export class ContactModule {}
