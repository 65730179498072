export const Config = {
    toolbar: {
        items: [
            'save',
            'undo',
            'redo',
            'heading',
            '|',
            'bold',
            'italic',
            'underline',
            'strikethrough',
            '|',
            'bulletedList',
            'numberedList',
            '|',
            'alignment',
            'indent',
            'outdent',
            'lineHeight',
            '|',
            'link',
            'imageUpload',
            '|',
            'fontBackgroundColor',
            'fontColor',
            'fontFamily',
            'fontSize'
        ]
    },
    language: 'en',
    image: {
        toolbarLocation: 'bottom',
        styles: ['alignLeft', 'alignCenter', 'alignRight'],
        toolbar: [
            'imageStyle:alignLeft',
            'imageStyle:alignCenter',
            'imageStyle:alignRight',
            '|',
            'imageResize',
            '|',
            'imageTextAlternative'
        ]
    }
};

export const ConfigTitleAndSubtitle = {
    toolbar: {
        items: ['undo', 'redo', 'bold', 'italic', 'underline', 'strikethrough', '|', 'alignment', '|', 'fontColor', 'fontFamily']
    },
    language: 'en'
};
