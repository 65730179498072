import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
    selector: '[appScrollableText]'
})
export class ScrollableTextDirective implements AfterViewInit {
    constructor(private el: ElementRef) {}

    @HostListener('window:resize', ['$event.target'])
    onResize() {
        this.changeStyle();
    }

    ngAfterViewInit() {
        this.changeStyle();
    }

    changeStyle() {
        if (this.el.nativeElement.scrollWidth > this.el.nativeElement.offsetWidth) {
            this.el.nativeElement.style['padding-right'] = '100%';
            this.el.nativeElement.style.animation = 'marquee 15s linear infinite';
        } else {
            this.el.nativeElement.style['padding-right'] = '0%';
            this.el.nativeElement.style.animation = '';
        }
    }
}
