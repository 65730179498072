<div class="pages-body login-page p-d-flex p-flex-column">
    <div class="p-as-center p-mt-auto p-mb-auto p-shadow-12">
        <form [formGroup]="loginForm">
            <div class="pages-panel card p-d-flex p-flex-column custom-color-form">
                <div class="pages-header p-px-3 p-py-1">
                    <h2>Пријава</h2>
                </div>

                <h4>Добродошли</h4>

                <div class="pages-detail p-mb-6 p-px-6">Унесите корисничко име и лозинку како бисте се улоговали</div>

                <div class="input-panel p-d-flex p-flex-column p-px-3">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-envelope"></i>
                        </span>
                        <span class="p-float-label">
                            <input
                                aria-describedby="email-required email-invalid"
                                formControlName="email"
                                type="text"
                                id="email"
                                pInputText
                            />
                            <label for="email">Емаил</label>
                        </span>
                    </div>

                    <div class="p-field p-fluid">
                        <small
                            *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.required"
                            id="email-required"
                            class="p-error"
                            >Емаил је обавезан</small
                        >
                        <small
                            *ngIf="loginForm.controls.email.touched && loginForm.controls.email.errors?.email"
                            id="email-invalid"
                            class="p-error"
                            >Емаил није у адекватном формату</small
                        >
                    </div>

                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">
                            <i class="pi pi-lock"></i>
                        </span>
                        <span class="p-float-label">
                            <input
                                aria-describedby="password-required password-invalid"
                                formControlName="password"
                                type="password"
                                id="password"
                                pInputText
                            />
                            <label for="password">Лозинка</label>
                        </span>
                    </div>

                    <div class="p-field p-fluid">
                        <small
                            *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.required"
                            id="password-required"
                            class="p-error"
                            >Лозинка је обавезна</small
                        >
                        <small
                            *ngIf="loginForm.controls.password.touched && loginForm.controls.password.errors?.minlength"
                            id="password-invalid"
                            class="p-error"
                            >Лозинка је мања од 6 карактера</small
                        >
                    </div>

                    <button
                        [disabled]="loginForm.invalid"
                        (click)="login()"
                        class="p-button p-mb-6 p-px-3"
                        label="Пријави се"
                        pButton
                        pRipple
                    ></button>
                </div>
            </div>
        </form>
    </div>
</div>
