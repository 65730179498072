<p-dialog
    [visible]="true"
    (visibleChange)="close()"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [style]="{ width: '1200px' }"
    [closeOnEscape]="true"
>
    <p-progressBar *ngIf="uploading" [value]="uploadProgress" [showValue]="false"></p-progressBar>
    <p-fileUpload
        *ngIf="selectedOption !== 'video'"
        [accept]="accept"
        [customUpload]="true"
        (uploadHandler)="upload($event)"
        chooseLabel="Изабери"
        uploadLabel="Пошаљи"
        cancelLabel="Уклони"
    ></p-fileUpload>
    <div *ngIf="selectedOption === 'video'">
        <span class="youtubeVideo p-float-label">
            <input [(ngModel)]="youtubeLink" pInputText />
            <label for="rows">Унесите YouTube линк до видеа</label>
        </span>
        <button
            *ngIf="selectedOption === 'video'"
            [disabled]="youtubeLink === ''"
            (click)="uploadYoutube(youtubeLink)"
            icon="pi pi-plus"
            class="p-button-info youtubeVideoAdd"
            type="button"
            label="Додај видео"
            pButton
            pRipple
        ></button>
    </div>
    <p-dataView #dv [value]="files" [rows]="6" [totalRecords]="totalRecords" [loading]="loading" layout="grid">
        <ng-template pTemplate="header">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                <p-dropdown
                    [disabled]="selectionDisabled"
                    [options]="selectOptions"
                    [ngModel]="selectedOption"
                    (ngModelChange)="fileTypeChanged($event)"
                    placeholder="Тип фајла"
                    styleClass="p-mb-2 p-mb-md-0"
                ></p-dropdown>
                <span class="p-input-icon-left p-mb-2 p-mb-md-0">
                    <i class="pi pi-search"></i>
                    <input
                        [ngModel]="search"
                        (ngModelChange)="searchInputChanged($event)"
                        type="search"
                        pInputText
                        placeholder="Претрага по називу"
                    />
                </span>
            </div>
        </ng-template>
        <ng-template let-file pTemplate="gridItem">
            <div class="p-col-12 p-md-4">
                <div class="file-grid-item card">
                    <div class="file-grid-item-content">
                        <div *ngIf="file.file_type === 'image'" [style]="{ height: imageProperties.height }" class="imageContainer">
                            <img width="100%" [src]="file.file" />
                        </div>
                        <div *ngIf="file.file_type === 'audio'">
                            <audio class="audio" controls>
                                <source [src]="file.file" />
                            </audio>
                        </div>
                        <div *ngIf="file.file_type === 'video'">
                            <youtube-player [videoId]="getVideoId(file.name)" suggestedQuality="highres" [width]="300" [height]="220">
                            </youtube-player>
                        </div>
                        <div *ngIf="file.file_type === 'download'" [style]="{ height: imageProperties.height }" class="downloadContainer">
                            <img [src]="'/assets/images/icons/download-icon.png'" />
                        </div>
                        <div class="file-name">{{ file.name }}</div>
                        <div class="car-buttons p-mt-5">
                            <p-button
                                (click)="select(file)"
                                type="button"
                                styleClass="p-button-info p-button-rounded p-mr-2"
                                icon="pi pi-plus"
                            ></p-button>
                            <p-button
                                (click)="delete(file.id)"
                                type="button"
                                styleClass="p-button-info p-button-rounded"
                                icon="pi pi-trash"
                            ></p-button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <div>
                <p-paginator #paginator [rows]="6" [totalRecords]="totalRecords" (onPageChange)="paginate($event)"></p-paginator>
            </div>
        </ng-template>
    </p-dataView>
</p-dialog>
