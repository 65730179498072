import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { SelectItem } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { Page } from 'src/modules/shared/model/page';
import { ExhibitionState } from 'src/modules/shared/states/exhibition/exhibition.state';
import {
    AddNewSection,
    AddSectionsToGallery,
    ApplyTemplate,
    ChangePageOrder,
    ChangeSelectedPage,
    ConvertPageToGallery,
    RevertPageFromGallery
} from 'src/modules/shared/states/exhibition/exhibition.state.actions';
import { ExhibitionService } from '../../services/exhibition.service';
import {
    ChangeSelectedLanguage,
    AddPage,
    RemovePage,
    ChangePageLanguage,
    MakeIntroductoryPage
} from '../../../shared/states/exhibition/exhibition.state.actions';
import { UtilityService } from 'src/modules/shared/services/utility.service';
import { Section } from 'src/modules/shared/model/section';
import { File } from '../../../shared/model/file';

@Component({
    selector: 'app-page-management',
    templateUrl: './page-management.component.html',
    styleUrls: ['./page-management.component.scss']
})
export class PageManagementComponent implements OnInit, OnDestroy {
    public selectedPage$!: Observable<number>;
    public selectedLanguage$!: Observable<string>;

    public pages$!: Subscription;
    public page$!: Observable<Page | undefined>;

    public pages: SelectItem[];
    public languages: SelectItem[];
    public templates: SelectItem[];

    public selectedItemForOrderChange: SelectItem;
    public selectedTemplateForApplying: SelectItem;

    public backgroundDialog: boolean;
    public galleryManagementDialog: boolean;
    public imagesInGallery: File[];

    constructor(public store: Store, public exhibitionService: ExhibitionService, public utilityService: UtilityService) {
        this.pages = [];
        this.languages = [];
        this.templates = [];
        this.selectedItemForOrderChange = { label: '', value: '' };
        this.selectedTemplateForApplying = { label: '', value: '' };
        this.backgroundDialog = false;
        this.galleryManagementDialog = false;
        this.imagesInGallery = [];
    }

    ngOnInit(): void {
        this.exhibitionService.getLanguages().subscribe((ret) => {
            this.selectedPage$ = this.store.select((state) => state.exhibition.selectedPage);
            this.selectedLanguage$ = this.store.select((state) => state.exhibition.selectedLanguage);

            this.pages$ = this.store.select(ExhibitionState.pages).subscribe((pages) => (this.pages = pages));
            this.page$ = this.store.select(ExhibitionState.page);

            this.languages = ret.map((item) => ({
                label: item.name,
                value: item.name
            }));
        });

        this.exhibitionService.getTemplatePages().subscribe((ret) => {
            this.templates = ret.map((item) => {
                item.template_sections = item.template_sections.map((section) => ({ ...section, content: null }));
                return {
                    label: `Шаблон ${item.id}`,
                    value: item
                };
            });
        });
    }

    ngOnDestroy(): void {
        this.pages$.unsubscribe();
    }

    changeExhibitionLanguage(language: string) {
        this.store.dispatch(new ChangeSelectedLanguage({ selectedLanguage: language }));
    }

    applyTemplate(event: any) {
        if (event !== null) {
            this.utilityService.displayConfirmationService('Да ли желите да примените шаблон?', 'Примена шаблона', () =>
                this.store.dispatch(
                    new ApplyTemplate({
                        row_number: event.row_number,
                        column_number: event.column_number,
                        sections: event.template_sections
                    })
                )
            );
            this.selectedTemplateForApplying = { label: '', value: '' };
        }
    }

    addPage(pages: SelectItem[]) {
        const newPage: any = {
            introductory: false,
            gallery: false,
            page_number: pages.length + 1,
            row_number: 20,
            column_number: 20,
            sections: [],
            background_color: '#FAE7CC',
            background_image_portrait: null,
            background_image_landscape: null
        };
        this.store.dispatch(new AddPage({ page: newPage }));
    }

    removePage() {
        this.utilityService.displayConfirmationService('Да ли желите да обришете страницу?', 'Брисање странице', () => {
            this.store.dispatch(new RemovePage());
            this.utilityService.displayToastrMessage('Успешно обрисана страница');
        });
    }

    changePage(page: number) {
        this.store.dispatch(new ChangeSelectedPage({ selectedPage: page }));
    }

    changePageOrder(event: any) {
        this.store.dispatch(new ChangePageOrder({ new_page_number: event.value }));
        this.selectedItemForOrderChange = { label: '', value: '' };
    }

    makePageIntroductory(introductory: boolean) {
        this.store.dispatch(new MakeIntroductoryPage({ introductory }));
    }

    changePageLanguage(pageLanguage: string) {
        this.store.dispatch(new ChangePageLanguage({ pageLanguage }));
    }

    openBackgroundDialog() {
        this.backgroundDialog = true;
    }

    closeBackgroundDialog() {
        this.backgroundDialog = false;
    }

    convertToGallery(sections: Section[]) {
        this.utilityService.displayConfirmationService('Да ли желите да направите галерију?', 'Прављење галерије', () => {
            if (!this.checkForSectionsThatAreNotImages(sections)) {
                this.utilityService.displayToastrMessage(
                    'Нису све секције слике! Обришите оне које нису или нек страница буде празна',
                    'Грешка',
                    'error'
                );
            } else {
                this.store.dispatch(new ConvertPageToGallery());
                this.utilityService.displayToastrMessage('Успешна конверзија у галерију');
            }
        });
    }

    revertFromGallery() {
        this.utilityService.displayConfirmationService(
            'Да ли желите да страница буде обична (све секције ће бити уклоњене)?',
            'Поништавање галерије',
            () => {
                this.store.dispatch(new RevertPageFromGallery({ sections: [] }));
                this.utilityService.displayToastrMessage('Успешна конверзија у обичну страницу');
            }
        );
    }

    checkForSectionsThatAreNotImages(sections: Section[]) {
        let allImages: boolean = true;
        sections.forEach((section: Section) => {
            if (section.content?.content_type !== 'image') {
                allImages = false;
            }
        });
        return allImages;
    }

    openGalleryManagementDialog(sections: Section[]) {
        const filesFromSections: File[] = [];
        sections.forEach((section: Section) => {
            if (section.content && section.content.file) {
                filesFromSections.push(section.content.file);
            }
        });
        this.imagesInGallery = filesFromSections;
        this.galleryManagementDialog = true;
    }

    closeGalleryManagementDialog() {
        this.galleryManagementDialog = false;
    }

    imagesForGallery(files: File[]) {
        const sectionsToAdd: Section[] = [];
        files.forEach((file: File, index) => {
            const section: Section = {
                section_order: index + 1,
                x_coordinate: 0,
                y_coordinate: 0,
                width: 0,
                height: 0,
                content: {
                    text_content: null,
                    content_type: 'image',
                    file
                },
                background_color: null,
                background_image_portrait: null,
                background_image_landscape: null
            };
            sectionsToAdd.push(section);
        });
        this.store.dispatch(new AddSectionsToGallery({ sections: sectionsToAdd }));
        this.closeGalleryManagementDialog();
    }
}
