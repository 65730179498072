import { StateContext } from '@ngxs/store';
import * as _ from 'lodash';
import { Page } from 'src/modules/shared/model/page';
import { ExhibitionStateModel } from '../exhibition.state';
import { ChangePageOrder } from '../exhibition.state.actions';

export const changePageOrder = (ctx: StateContext<ExhibitionStateModel>, action: ChangePageOrder) => {
    const state = ctx.getState();
    const pagesToIgnore: Page[] = _.cloneDeep(state.pages).filter((page) => page.language !== state.selectedLanguage);
    const pagesToReorder: Page[] = _.cloneDeep(state.pages).filter((page) => page.language === state.selectedLanguage);
    for (const page of pagesToReorder) {
        if (page.page_number === state.selectedPage) {
            page.page_number = action.payload.new_page_number;
        } else if (page.page_number === action.payload.new_page_number) {
            page.page_number = state.selectedPage;
        }
    }
    const pages = [...pagesToIgnore, ...pagesToReorder];
    ctx.patchState({
        pages,
        selectedPage: action.payload.new_page_number
    });
};
