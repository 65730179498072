import { StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import * as _ from 'lodash';
import { Page } from 'src/modules/shared/model/page';
import { Section } from 'src/modules/shared/model/section';
import { ExhibitionStateModel } from '../exhibition.state';
import { RemoveSectionContent } from '../exhibition.state.actions';

export const removeSectionContent = (ctx: StateContext<ExhibitionStateModel>, action: RemoveSectionContent) => {
    const state = ctx.getState();
    const sections: Section[] = _.cloneDeep(action.payload.sections).map((section) => {
        if (section.section_order === state.selectedSection) {
            section.content = null;
        }
        return section;
    });
    ctx.setState(
        patch({
            pages: updateItem<Page>(
                (item) => item?.page_number === state.selectedPage && item?.language === state.selectedLanguage,
                patch({ sections })
            )
        })
    );
};
