import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ContactService } from '../../services/contact.service';
import { ContactMessageResponse } from '../../../shared/model/contact-message-response';
import { MessageService } from 'primeng/api';
import { UtilityService } from 'src/modules/shared/services/utility.service';

@Component({
    selector: 'app-send-email-dialog',
    templateUrl: './send-email-dialog.component.html',
    styleUrls: ['./send-email-dialog.component.scss']
})
export class SendEmailDialogComponent implements OnInit, OnChanges {
    @Input() public visible: boolean;
    @Input() public message: any;
    @Output() public closeDialog: EventEmitter<boolean>;
    @Output() public responded: EventEmitter<any>;
    public responseForm: FormGroup;

    constructor(public fb: FormBuilder, public contactService: ContactService, public utilityService: UtilityService) {
        this.visible = false;
        this.message = {
            id: 0,
            email: ''
        };
        this.closeDialog = new EventEmitter();
        this.responded = new EventEmitter();
        this.responseForm = this.fb.group({
            email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
            title: ['', [Validators.required, Validators.maxLength(100)]],
            message: ['', [Validators.required, Validators.maxLength(1000)]]
        });
    }

    ngOnInit() {}

    ngOnChanges() {
        this.responseForm.reset();
        this.responseForm.controls.email.setValue(this.message.email);
    }

    sendResponse() {
        const contactMessage: ContactMessageResponse = {
            contact_message: this.message.id,
            title: this.responseForm.controls.title.value,
            message: this.responseForm.controls.message.value
        };
        this.contactService.sendResponse(contactMessage).subscribe((response) => {
            this.utilityService.displayToastrMessage('Успешно сте одговорили на поруку');
            this.responded.emit({
                id: this.message.id,
                replied: response.replied
            });
            this.hide();
        });
    }

    hide() {
        this.responseForm.reset();
        this.visible = false;
        this.closeDialog.emit(this.visible);
    }
}
