<div class="p-grid dashboard">
    <div class="p-col-12 p-md-8">
        <app-downloads-month [downloadStatistic]="downloadStatistic"></app-downloads-month>
    </div>
    <div class="p-col-12 p-md-4">
        <app-downloads-year [downloadStatistic]="downloadStatistic"></app-downloads-year>
    </div>
    <div class="p-col-12 p-md-4">
        <app-downloads-country [downloadByCountryStatistic]="downloadByCountryStatistic"></app-downloads-country>
    </div>
    <div class="p-col-12 p-md-8">
        <app-visits-month [visitStatistic]="visitStatistic"></app-visits-month>
    </div>
    <div class="p-col-12">
        <app-visits-downloads-compared [downloadStatistic]="downloadStatistic" [visitStatistic]="visitStatistic"></app-visits-downloads-compared>
    </div>
</div>
