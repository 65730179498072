import { StateContext } from '@ngxs/store';
import * as _ from 'lodash';
import { Page } from 'src/modules/shared/model/page';
import { ExhibitionStateModel } from '../exhibition.state';
import { MakeIntroductoryPage } from '../exhibition.state.actions';

export const makeIntroductoryPage = (ctx: StateContext<ExhibitionStateModel>, action: MakeIntroductoryPage) => {
    const state = ctx.getState();
    const pages: Page[] = _.cloneDeep(state.pages);
    for (const page of pages) {
        if (page.language === state.selectedLanguage) {
            if (page.page_number === state.selectedPage) {
                page.introductory = action.payload.introductory;
            } else {
                page.introductory = false;
            }
        }
    }
    ctx.patchState({
        pages
    });
};
