<div class="layout-topbar p-shadow-4">
    <div class="layout-topbar-left">
        <a class="layout-topbar-logo" routerLink="/pozorisni-muzej/izlozbe/pocetna">
            <img id="app-logo" src="assets/images/logo/logo.png" alt="ultima-layout" style="height: 3rem" />
        </a>
    </div>

    <div class="layout-topbar-right">
        <div class="layout-topbar-actions-left">
            <ul class="layout-topbar-items">
                <li class="layout-topbar-item p-d-inline-flex">
                    <a
                        routerLink="/pozorisni-muzej/izlozbe/pocetna"
                        class="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2"
                        pRipple
                    >
                        <img src="assets/images/icons/home-icon.png" alt="exhibition" style="width: 32px; height: 32px" />
                        <span style="margin-left: 10px">Почетна страница</span>
                    </a>
                </li>
                <li *ngIf="isAuthenticated" class="layout-topbar-item p-d-inline-flex">
                    <a
                        routerLink="/pozorisni-muzej/izlozbe"
                        class="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2"
                        pRipple
                    >
                        <img src="assets/images/icons/exhibition-icon.png" alt="exhibition" style="width: 32px; height: 32px" />
                        <span style="margin-left: 10px">Изложбе</span>
                    </a>
                </li>
                <li *ngIf="isAuthenticated" class="layout-topbar-item p-d-inline-flex">
                    <a
                        routerLink="/pozorisni-muzej/statistika"
                        class="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2"
                        pRipple
                    >
                        <img src="assets/images/icons/stats-icon.png" alt="exhibition" style="width: 32px; height: 32px" />
                        <span style="margin-left: 10px">Статистика</span>
                    </a>
                </li>
                <li *ngIf="!isAuthenticated" class="layout-topbar-item p-d-inline-flex">
                    <a
                        routerLink="/pozorisni-muzej/informacije"
                        class="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2"
                        pRipple
                    >
                        <img src="assets/images/icons/contact-icon.png" alt="exhibition" style="width: 32px; height: 32px" />
                        <span style="margin-left: 10px">Контактирајте нас</span>
                    </a>
                </li>
                <li *ngIf="isAuthenticated" class="layout-topbar-item p-d-inline-flex">
                    <a
                        routerLink="/pozorisni-muzej/informacije/poruke"
                        class="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2"
                        pRipple
                    >
                        <img src="assets/images/icons/message-list-icon.png" alt="exhibition" style="width: 32px; height: 32px" />
                        <span style="margin-left: 10px">Приказ пристиглих порука</span>
                    </a>
                </li>
            </ul>
        </div>
        <div *ngIf="isAuthenticated" class="layout-topbar-actions-right">
            <ul class="layout-topbar-items">
                <li class="layout-topbar-item">
                    <a
                        class="layout-topbar-action p-d-flex p-dir-row p-jc-center p-ai-center p-px-2"
                        (click)="onTopbarItemClick($event)"
                        pRipple
                    >
                        <img src="assets/images/icons/account-icon.png" alt="avatar" style="width: 32px; height: 32px" />
                    </a>

                    <ul
                        class="layout-topbar-action-panel p-shadow-6 subbar"
                        [@topbarActionPanelAnimation]="'visible'"
                        *ngIf="activeTopbarItem"
                    >
                        <li (click)="logout()" class="layout-topbar-action-item">
                            <a class="p-d-flex p-flex-row p-ai-center custom-text-color" pRipple>
                                <i class="pi pi-power-off" [ngClass]="{ 'p-mr-2': !app.isRTL, 'p-ml-2': app.isRTL }"></i>
                                <span>Излогуј се</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
