import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { NgxWidgetGridComponent } from 'ngx-custom-greenenvy-widget-grid';
import { Subscription } from 'rxjs';
import { Page } from 'src/modules/shared/model/page';
import { Section } from 'src/modules/shared/model/section';
import { UtilityService } from 'src/modules/shared/services/utility.service';
import { ExhibitionState } from 'src/modules/shared/states/exhibition/exhibition.state';
import {
    AddNewSection,
    ChangeEditMode,
    ChangeSectionContent,
    ChangeSectionCoordinates,
    ChangeSelectedSection
} from 'src/modules/shared/states/exhibition/exhibition.state.actions';
import { FileService } from '../../services/file.service';
import * as Editor from '../../../../assets/CKEditor5/ckeditor';
import { Config } from '../../config/editor.config';
import { UploadAdapter } from '../../config/upload.adapter';
import { HttpClient } from '@angular/common/http';

@Component({
    selector: 'app-page-content',
    templateUrl: './page-content.component.html',
    styleUrls: ['./page-content.component.scss']
})
export class PageContentComponent implements OnInit, OnDestroy {
    @ViewChild('grid') public grid!: NgxWidgetGridComponent;
    @ViewChild('textEditor') textEditor!: any;

    public selectedSectionSubscription!: Subscription;
    public selectedSection: number;

    public pageSubscription!: Subscription;
    public page: Page | undefined;
    public sections: any[];

    public editableSubscription!: Subscription;
    public editable: boolean;

    public editor: any;
    public config: any;

    public resizeDirections: any;

    public masonryList: string[] = [];

    constructor(public store: Store, public utilityService: UtilityService, public fileService: FileService, public http: HttpClient) {
        this.selectedSection = 0;

        this.sections = [];

        this.editable = false;

        this.editor = Editor;
        this.config = Config;

        this.resizeDirections = ['SW', 'SE'];

        this.masonryList = [
            'http://localhost:4200/media/slika2.jpeg',
            'http://localhost:4200/media/slika4.jpeg',
            'http://localhost:4200/media/slika1.jpeg',
            'http://localhost:4200/media/slika5.jpeg',
            'http://localhost:4200/media/exhibition-6.jpg',
            'http://localhost:4200/media/exhibition-7.jpg',
            'http://localhost:4200/media/slika3.jpeg',
            'http://localhost:4200/media/slika1_Nf0VJAt.jpg',
            'http://localhost:4200/media/slika4_urczXEE.jpg',
            'http://localhost:4200/media/slika2_ctpUdb1.jpg'
        ];
    }

    @HostListener('window: onSaveClicked', ['$event'])
    onClick(event: any) {
        this.store.dispatch(
            new ChangeSectionContent({
                content: {
                    content_type: 'text',
                    text_content: this.textEditor.editorInstance.getData(),
                    file: null
                }
            })
        );
        this.utilityService.displayToastrMessage('Успешно сте сачували текстуални садржај', 'Успешно', 'success');
    }

    ngOnInit(): void {
        this.selectedSectionSubscription = this.store
            .select((state) => state.exhibition.selectedSection)
            .subscribe((selectedSection: number) => {
                this.selectedSection = selectedSection;
            });
        this.pageSubscription = this.store.select(ExhibitionState.page).subscribe((page: Page | undefined) => {
            this.sections = this.generatePositions(page);
            this.page = page;
        });
        this.editableSubscription = this.store
            .select((state) => state.exhibition.editable)
            .subscribe((editable: boolean) => {
                this.editable = editable;
            });
    }

    ngOnDestroy(): void {
        this.selectedSectionSubscription.unsubscribe();
        this.pageSubscription.unsubscribe();
    }

    generatePositions(page: Page | undefined): any[] {
        if (!page) {
            return [];
        }
        const sections: any[] = [];
        for (const section of page.sections) {
            sections.push({
                ...section,
                position: {
                    section_order: section.section_order,
                    left: section.x_coordinate,
                    top: section.y_coordinate,
                    width: section.width,
                    height: section.height
                }
            });
        }
        return sections;
    }

    positionChanged(event: any, section_order: number) {
        this.store.dispatch(
            new ChangeSectionCoordinates({
                section_order,
                left: event.left,
                top: event.top,
                width: event.width,
                height: event.height
            })
        );
    }

    changeSelection(selectedSection: number, sectionType: string | undefined) {
        this.store.dispatch(new ChangeSelectedSection({ selectedSection }));
        if (!this.editable && sectionType !== 'text') {
            this.editable = true;
            this.store.dispatch(new ChangeEditMode({ editable: this.editable }));
        }
    }

    addSection(sectionOrder: number) {
        const nextPosition = this.grid.getNextPosition();
        if (nextPosition) {
            const section: Section = {
                section_order: sectionOrder,
                x_coordinate: nextPosition.left,
                y_coordinate: nextPosition.top,
                width: nextPosition.width,
                height: nextPosition.height,
                content: null,
                background_color: null,
                background_image_portrait: null,
                background_image_landscape: null
            };
            this.store.dispatch(new AddNewSection({ section }));
        } else {
            this.utilityService.displayToastrMessage('Нема слободног простора на гриду. Обришите неку од секција!', 'Грешка', 'error');
        }
    }

    onReady(editor: any, data: any) {
        editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) =>
            new UploadAdapter(loader, '/api/file/upload/', this.http);
    }

    getSectionInOrderForGaller(sections: Section[]) {
        const sectionsToSort = [...sections];
        return sectionsToSort.sort((a: Section, b: Section) => (a.section_order > b.section_order ? 1 : -1));
    }

    getVideoId(youtubeUrl: string) {
        let retVal: RegExpMatchArray | null = null;
        const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        retVal = youtubeUrl.match(rx);
        if (retVal !== null) {
            return retVal[1];
        }
        return '';
    }
}
