import { Component, OnInit } from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { AppComponent } from '../../app.component';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { AuthState } from 'src/modules/shared/states/auth/auth.state';
import { AuthService } from 'src/modules/shared/services/auth.service';
import { Logout } from 'src/modules/shared/states/auth/auth.state.actions';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    animations: [
        trigger('topbarActionPanelAnimation', [
            transition(':enter', [
                style({ opacity: 0, transform: 'scaleY(0.8)' }),
                animate('.12s cubic-bezier(0, 0, 0.2, 1)', style({ opacity: 1, transform: '*' }))
            ]),
            transition(':leave', [animate('.1s linear', style({ opacity: 0 }))])
        ])
    ]
})
export class NavbarComponent implements OnInit {
    public activeTopbarItem: boolean;
    public isAuthenticated: boolean;

    constructor(public app: AppComponent, public router: Router, public store: Store, public auth: AuthService) {
        this.activeTopbarItem = false;
        this.isAuthenticated = false;
    }

    ngOnInit(): void {
        this.isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);
    }

    onTopbarItemClick(event: any) {
        this.activeTopbarItem = !this.activeTopbarItem;
        event.preventDefault();
    }

    logout() {
        this.store.dispatch(new Logout()).subscribe(() => this.router.navigate(['']));
        this.isAuthenticated = this.store.selectSnapshot(AuthState.isAuthenticated);
    }
}
