<p-accordionTab [selected]="false" header="QR код странице" style="margin-bottom: 0">
    <div *ngIf="url" class="qr-code-container">
        <div class="qr-controls">
            <p-button
                *ngFor="let size of printSizes"
                [label]="size.label"
                icon="pi pi-print"
                (onClick)="selectedPrintSize = size; printQrCode()"
                styleClass="p-button-secondary"
            >
            </p-button>
        </div>
        <qrcode [qrdata]="url" [width]="240" [errorCorrectionLevel]="'M'" style="display: block"> </qrcode>
    </div>
</p-accordionTab>
