import { Component, Input, OnChanges } from '@angular/core';
import { Statistic } from 'src/modules/shared/model/statistic';
import { lineChartOptions } from '../../config/line-chart.options';
import { COLORS } from '../../constants/colors';
import { StatisticsService } from '../../services/statistics.service';

@Component({
    selector: 'app-visits-downloads-compared',
    templateUrl: './visits-downloads-compared.component.html',
    styleUrls: ['./visits-downloads-compared.component.scss']
})
export class VisitsDownloadsComparedComponent implements OnChanges {
    @Input() public downloadStatistic: Statistic[];
    @Input() public visitStatistic: Statistic[];
    public lineChartOptions: any;
    public lineChartData: any;

    constructor(public statisticsService: StatisticsService) {
        this.lineChartOptions = lineChartOptions();
        this.lineChartData = {
            datasets: []
        };
        this.downloadStatistic = [];
        this.visitStatistic = [];
    }

    ngOnChanges(): void {
        this.initDownloadStatistic();
        this.initVisitStatistic();
    }

    initDownloadStatistic(): void {
        const years: number[] = [...new Set(this.downloadStatistic.map((item) => item.year))].sort();

        const pie: any = {
            label: 'Број скидања апликације по години',
            data: [],
            backgroundColor: COLORS[0],
            borderColor: COLORS[0],
            fill: false
        };
        for (const year of years) {
            const statisticForYearSum: Statistic[] = this.downloadStatistic.filter((item) => item.year === year);
            pie.data.push(statisticForYearSum.reduce((accum, item) => accum + item.total, 0));
        }

        this.lineChartData = {
            labels: years,
            datasets: [...this.lineChartData.datasets, pie]
        };
    }

    initVisitStatistic(): void {
        const years: number[] = [...new Set(this.visitStatistic.map((item) => item.year))].sort();

        const pie: any = {
            label: 'Број посета апликацији по години',
            data: [],
            backgroundColor: COLORS[1],
            borderColor: COLORS[1],
            fill: false
        };
        for (const year of years) {
            const statisticForYearSum: Statistic[] = this.visitStatistic.filter((item) => item.year === year);
            pie.data.push(statisticForYearSum.reduce((accum, item) => accum + item.total, 0));
        }

        this.lineChartData = {
            labels: years,
            datasets: [...this.lineChartData.datasets, pie]
        };
    }
}
