import { StateContext } from '@ngxs/store';
import { ExhibitionStateModel } from '../exhibition.state';
import { ChangeBackground } from '../exhibition.state.actions';

export const changeBackground = (ctx: StateContext<ExhibitionStateModel>, action: ChangeBackground) => {
    ctx.patchState({
        background_color: action.payload.backgroundColor,
        background_image_portrait: action.payload.backgroundImagePortrait,
        background_image_landscape: action.payload.backgroundImageLandscape
    });
};
