import { Component, OnInit, Output, EventEmitter, Input, ViewChild, OnDestroy } from '@angular/core';
import { Paginator } from 'primeng/paginator';
import { File } from '../../../shared/model/file';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { UtilityService } from 'src/modules/shared/services/utility.service';
import { FileService } from '../../services/file.service';

@Component({
    selector: 'app-gallery-management',
    templateUrl: './gallery-management.component.html',
    styleUrls: ['./gallery-management.component.scss']
})
export class GalleryManagementComponent implements OnInit, OnDestroy {
    @ViewChild('paginator', { static: false }) public paginator!: Paginator;

    @Input() public imagesInGallery: File[];
    @Output() public closeDialog: EventEmitter<void>;
    @Output() public imagesForGallery: EventEmitter<File[]>;

    // Files
    public files: File[];
    public totalRecords: number;
    public loading: boolean;
    // Search input
    public search: string;
    public searchChanged: Subject<string>;
    // Image property
    public imageProperties: any;
    //Uploading progress.
    public uploading: boolean;

    constructor(public fileService: FileService, public utilityService: UtilityService) {
        this.imagesInGallery = [];
        this.closeDialog = new EventEmitter<void>();
        this.imagesForGallery = new EventEmitter<File[]>();

        this.files = [];
        this.totalRecords = 0;
        this.loading = false;

        this.search = '';
        this.searchChanged = new Subject<string>();
        this.searchChanged.pipe(debounceTime(500), distinctUntilChanged()).subscribe((searchInput) => {
            this.search = searchInput;
            this.paginator.changePage(0);
        });

        this.imageProperties = {
            height: '10px'
        };

        this.uploading = false;

        this.uploading = false;
    }

    ngOnInit(): void {
        this.loadFiles(1);
    }

    ngOnDestroy(): void {
        this.searchChanged.unsubscribe();
    }

    loadFiles(page: number) {
        this.loading = true;
        this.fileService.getFiles(6, page, 'image', this.search).subscribe((files) => {
            this.utilityService.findMaxHeightForImageDiv(files.results, this.imageProperties, 254);
            this.files = files.results;
            this.totalRecords = files.count;
            this.loading = false;
        });
    }

    searchInputChanged(search: string) {
        this.searchChanged.next(search);
    }

    paginate(event: any) {
        this.loadFiles(event.page + 1);
    }

    close() {
        this.closeDialog.emit();
    }

    itemSelected(file: string) {
        const filesInGallery: string[] = this.imagesInGallery.map((image) => image.file);
        return filesInGallery.indexOf(file) > -1 ? true : false;
    }

    addToGallery(file: File) {
        this.imagesInGallery.push(file);
    }

    removeFromGallery(file: File) {
        this.imagesInGallery = this.imagesInGallery.filter((image: File) => image.file !== file.file);
    }

    applyImagesToGallery() {
        this.imagesForGallery.emit(this.imagesInGallery);
    }

    async upload(uploadEvent: any) {
        this.uploading = true;
        for (const file of uploadEvent.files) {
            console.log(file);
            const formData: FormData = new FormData();
            formData.append('file', file);
            formData.append('name', file.name);
            formData.append('file_type', 'image');
            await this.fileService.uploadFile(formData).toPromise();
        }
        this.uploading = false;
        this.search = '';
        this.paginator.changePage(0);
        this.utilityService.displayToastrMessage('Успешно слање датотеке', 'Успешно');
    }

    deleteFile(file: File, fileId: number) {
        this.utilityService.displayConfirmationService('Да ли желите да обришете слику?', 'Брисање слике', () =>
            this.fileService.deleteFile(fileId).subscribe((ret) => {
                const page: number = this.paginator.getPage();
                this.utilityService.displayToastrMessage('Успешно сте избрисали слику!');
                this.removeFromGallery(file);
                if (this.totalRecords % 6 === 1 && page !== 0) {
                    this.paginator.changePage(page - 1);
                } else {
                    this.loadFiles(page + 1);
                }
            })
        );
    }
}
