import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Statistic } from 'src/modules/shared/model/statistic';
import { StatisticByCountry } from 'src/modules/shared/model/statistic-by-country';

@Injectable({
    providedIn: 'root'
})
export class StatisticsService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(public http: HttpClient) {}

    getDownloadStatistic(): Observable<Statistic[]> {
        return this.http.get<Statistic[]>('api/statistic/month/?type=Download', { headers: this.headers });
    }

    getVisitStatistic(): Observable<Statistic[]> {
        return this.http.get<Statistic[]>('api/statistic/month/?type=Open', { headers: this.headers });
    }

    getDownloadsByCountryStatistic(): Observable<StatisticByCountry[]> {
        return this.http.get<StatisticByCountry[]>('api/statistic/country/', { headers: this.headers });
    }
}
