import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { State, Selector } from '@ngxs/store';
import { SelectItem } from 'primeng/api';
import { ExhibitionService } from 'src/modules/exhibition/services/exhibition.service';
import { Page } from '../../model/page';
import { File } from '../../model/file';
import {
    ChangeSelectedLanguage,
    AddPage,
    RemovePage,
    ChangePageLanguage,
    MakeIntroductoryPage,
    AddNewSection,
    RemoveContentFromAllSections,
    RemoveSection,
    RemoveSectionContent,
    ChangeSelectedSection,
    ChangeSectionContent,
    ChangeThumbnail,
    ApplyTemplate,
    ChangePageOrder,
    ChangeSectionCoordinates,
    ChangeSectionOrder,
    NewExhibition,
    ChangeSubtitle,
    UpdateExhibitionId,
    ChangePageBackgroundForAll,
    AddSectionsToGallery,
    ConvertPageToGallery,
    RevertPageFromGallery,
    ChangeSectionBackground,
    ChangePageBackground,
    ChangeBackground
} from './exhibition.state.actions';
import {
    ChangeEditMode,
    ChangeNote,
    ChangePublishProperty,
    ChangeSelectedPage,
    ChangeTitle,
    LoadExhibition
} from './exhibition.state.actions';
import { ChangePageRowNumber, ChangePageColumnNumber } from './exhibition.state.actions';
import { attachAction } from '@ngxs-labs/attach-action';
import { changeSelectedPage } from './actions/change-selected-page.action';
import { changeSelectedLanguage } from './actions/change-selected-language.action';
import { changeSelectedSection } from './actions/change-section-selection.action';
import { loadExhibition } from './actions/load-exhibition.action';
import { changePublishProperty } from './actions/change-publish-property.action';
import { changeEditMode } from './actions/change-edit-mode.action';
import { changeNote } from './actions/change-note.action';
import { changeTitle } from './actions/change-title.action';
import { changeSubtitle } from './actions/change-subtitle.action';
import { newExhibition } from './actions/set-new-exhibition.action';
import { changeThumbnail } from './actions/change-thumbnail.action';
import { changePageRowNumber } from './actions/change-page-row-number.action';
import { changePageColumnNumber } from './actions/change-page-column-number.action';
import { addPage } from './actions/add-page.action';
import { removePage } from './actions/remove-page.action';
import { changePageLanguage } from './actions/change-page-language.action';
import { changePageOrder } from './actions/change-page-order.action';
import { applyTemplate } from './actions/apply-template.action';
import { makeIntroductoryPage } from './actions/make-page-introductory.action';
import { addNewSection } from './actions/add-section.action';
import { removeContentFromAllSections } from './actions/remove-section-content-all.action';
import { removeSectionContent } from './actions/remove-section-content.action';
import { removeSection } from './actions/remove-section.action';
import { changeSectionContent } from './actions/change-section-content.action';
import { changeSectionCoordinates } from './actions/change-section-coordinates.action';
import { changeSectionOrder } from './actions/change-section-order.action';
import { updateExhibitionId } from './actions/update-exhibition-id.action';
import { changePageBackgroundForAll } from './actions/change-page-background-for-all.action';
import { addSectionsToGallery } from './actions/add-sections-to-gallery.action';
import { convertPageToGallery } from './actions/convert-page-to-gallery.action';
import { revertPageFromGallery } from './actions/revert-page-from-gallery.action';
import { changeSectionBackground } from './actions/change-section-background.actions';
import { changePageBackground } from './actions/change-page-background.action';
import { changeBackground } from './actions/change-background.action';

export interface ExhibitionStateModel {
    loading: boolean;
    editable: boolean;
    selectedPage: number;
    selectedLanguage: string;
    selectedSection: number;

    id: number;
    title: string;
    subtitle: string;
    thumbnail: File | null;
    create_date: Date;
    note: string;
    published: boolean;
    background_color: string | null;
    background_image_portrait: File | null;
    background_image_landscape: File | null;
    pages: Page[];
}

@State<ExhibitionStateModel>({
    name: 'exhibition',
    defaults: {
        loading: false,
        editable: false,
        selectedPage: 1,
        selectedLanguage: 'Српски',
        selectedSection: 0,

        id: 0,
        title: 'Some title',
        subtitle: 'Some subtitle',
        thumbnail: null,
        create_date: new Date(),
        note: 'Some entered note for exhibition',
        published: false,

        background_color: '#FAE7CC',
        background_image_portrait: null,
        background_image_landscape: null,

        pages: [
            {
                introductory: false,
                gallery: false,
                page_number: 1,
                row_number: 20,
                column_number: 20,
                language: 'Serbian',

                background_color: '#FAE7CC',
                background_image_portrait: null,
                background_image_landscape: null,

                sections: [
                    {
                        section_order: 1,
                        x_coordinate: 1,
                        y_coordinate: 1,
                        width: 20,
                        height: 20,
                        content: null,

                        background_color: null,
                        background_image_portrait: null,
                        background_image_landscape: null
                    }
                ]
            }
        ]
    }
})
@Injectable()
export class ExhibitionState {
    constructor(public exhibitionService: ExhibitionService, public router: Router) {
        // Global properties
        attachAction(ExhibitionState, UpdateExhibitionId, updateExhibitionId);
        attachAction(ExhibitionState, LoadExhibition, loadExhibition(exhibitionService));
        attachAction(ExhibitionState, ChangeSelectedPage, changeSelectedPage);
        attachAction(ExhibitionState, ChangeSelectedLanguage, changeSelectedLanguage);
        attachAction(ExhibitionState, ChangeSelectedSection, changeSelectedSection);
        attachAction(ExhibitionState, ChangePublishProperty, changePublishProperty);
        attachAction(ExhibitionState, ChangeEditMode, changeEditMode);
        attachAction(ExhibitionState, ChangeNote, changeNote);
        attachAction(ExhibitionState, ChangeTitle, changeTitle);
        attachAction(ExhibitionState, ChangeSubtitle, changeSubtitle);
        attachAction(ExhibitionState, NewExhibition, newExhibition);
        attachAction(ExhibitionState, ChangeThumbnail, changeThumbnail);
        attachAction(ExhibitionState, ChangeBackground, changeBackground);
        //Page
        attachAction(ExhibitionState, ChangePageRowNumber, changePageRowNumber);
        attachAction(ExhibitionState, ChangePageColumnNumber, changePageColumnNumber);
        attachAction(ExhibitionState, AddPage, addPage);
        attachAction(ExhibitionState, RemovePage, removePage);
        attachAction(ExhibitionState, ChangePageLanguage, changePageLanguage);
        attachAction(ExhibitionState, ChangePageOrder, changePageOrder);
        attachAction(ExhibitionState, ApplyTemplate, applyTemplate);
        attachAction(ExhibitionState, MakeIntroductoryPage, makeIntroductoryPage);
        attachAction(ExhibitionState, ChangePageBackgroundForAll, changePageBackgroundForAll);
        attachAction(ExhibitionState, ChangePageBackground, changePageBackground);
        //Section
        attachAction(ExhibitionState, AddNewSection, addNewSection);
        attachAction(ExhibitionState, RemoveContentFromAllSections, removeContentFromAllSections);
        attachAction(ExhibitionState, RemoveSection, removeSection);
        attachAction(ExhibitionState, RemoveSectionContent, removeSectionContent);
        attachAction(ExhibitionState, ChangeSectionContent, changeSectionContent);
        attachAction(ExhibitionState, ChangeSelectedSection, changeSelectedSection);
        attachAction(ExhibitionState, ChangeSectionCoordinates, changeSectionCoordinates);
        attachAction(ExhibitionState, ChangeSectionOrder, changeSectionOrder);
        attachAction(ExhibitionState, ChangeSectionBackground, changeSectionBackground);
        //Gallery
        attachAction(ExhibitionState, AddSectionsToGallery, addSectionsToGallery);
        attachAction(ExhibitionState, ConvertPageToGallery, convertPageToGallery);
        attachAction(ExhibitionState, RevertPageFromGallery, revertPageFromGallery);
    }

    @Selector()
    static pages(state: ExhibitionStateModel): SelectItem[] {
        return state.pages
            .filter((item) => item.language === state.selectedLanguage)
            .sort((a, b) => a.page_number - b.page_number)
            .map((item) => {
                const introductory: string = item.introductory === true ? '(Naslovna)' : '';
                const selectItem: SelectItem = {
                    label: `Страница ${item.page_number} ${introductory}`,
                    value: item.page_number
                };
                return selectItem;
            });
    }

    @Selector()
    static page(state: ExhibitionStateModel): Page | undefined {
        return state.pages.find((item) => item.page_number === state.selectedPage && item.language === state.selectedLanguage);
    }

    @Selector()
    static pageBackgroundImageColor(state: ExhibitionStateModel): string | undefined {
        return state.pages.find((item) => item.page_number === state.selectedPage && item.language === state.selectedLanguage)
            ?.background_color;
    }

    @Selector()
    static pageBackgroundImagePortrait(state: ExhibitionStateModel): File | null | undefined {
        return state.pages.find((item) => item.page_number === state.selectedPage && item.language === state.selectedLanguage)
            ?.background_image_portrait;
    }

    @Selector()
    static pageBackgroundImageLandscape(state: ExhibitionStateModel): File | null | undefined {
        return state.pages.find((item) => item.page_number === state.selectedPage && item.language === state.selectedLanguage)
            ?.background_image_landscape;
    }

    @Selector()
    static sectionBackgroundColor(state: ExhibitionStateModel): string | null | undefined {
        return state.pages
            .find((item) => item.page_number === state.selectedPage && item.language === state.selectedLanguage)
            ?.sections.find((section) => section.section_order === state.selectedSection)?.background_color;
    }

    @Selector()
    static sectionBackgroundImagePortrait(state: ExhibitionStateModel): File | null | undefined {
        return state.pages
            .find((item) => item.page_number === state.selectedPage && item.language === state.selectedLanguage)
            ?.sections.find((section) => section.section_order === state.selectedSection)?.background_image_portrait;
    }

    @Selector()
    static sectionBackgroundImageLandscape(state: ExhibitionStateModel): File | null | undefined {
        return state.pages
            .find((item) => item.page_number === state.selectedPage && item.language === state.selectedLanguage)
            ?.sections.find((section) => section.section_order === state.selectedSection)?.background_image_landscape;
    }

    @Selector()
    static exhibition(state: ExhibitionStateModel): ExhibitionStateModel {
        return state;
    }

    @Selector()
    static editable(state: ExhibitionStateModel): boolean {
        return state.editable;
    }

    @Selector()
    static selectedSectionType(state: ExhibitionStateModel): string | undefined {
        return state.pages
            .find((item) => item.page_number === state.selectedPage && item.language === state.selectedLanguage)
            ?.sections.find((section) => section.section_order === state.selectedSection)?.content?.content_type;
    }
}
