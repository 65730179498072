import { StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { Page } from 'src/modules/shared/model/page';
import { ExhibitionStateModel } from '../exhibition.state';
import { ChangePageColumnNumber } from '../exhibition.state.actions';

export const changePageColumnNumber = (ctx: StateContext<ExhibitionStateModel>, action: ChangePageColumnNumber) => {
    const state = ctx.getState();
    ctx.setState(
        patch({
            pages: updateItem<Page>(
                (item) => item?.page_number === state.selectedPage && item?.language === state.selectedLanguage,
                patch({ column_number: action.payload.columnNumber })
            )
        })
    );
};
