<div class="p-grid p-d-flex p-jc-center">
    <div class="p-col-12 p-md-9">
        <ng-container *ngIf="page; else noContent">
            <app-page-view [title]="title" [subtitle]="subtitle" [page]="page"></app-page-view>
        </ng-container>
        <ng-template #noContent>
            <div class="card content" style="background-color: #fae7cc">
                <h1 class="p-d-flex p-jc-center">Нема странице за изабран језик</h1>
            </div>
        </ng-template>
    </div>
    <div class="p-col-12 p-md-7">
        <div class="card" style="background-color: #fae7cc">
            <p-paginator #paginator [rows]="1" [totalRecords]="pageNumber" (onPageChange)="paginate($event)"></p-paginator>
        </div>
    </div>
    <div class="p-col-12 p-md-2">
        <div class="card" style="background-color: #fae7cc">
            <p-dropdown
                class="p-d-flex p-jc-center"
                [autoDisplayFirst]="true"
                [options]="languages"
                [ngModel]="selectedLanguage"
                (ngModelChange)="selectedLanguageChagned($event)"
                placeholder="Језик"
                styleClass="p-mb-2 p-mb-md-0"
            ></p-dropdown>
        </div>
    </div>
</div>
