<div class="pages-body accessdenied-page p-d-flex p-flex-column">
    <div class="p-as-center p-mt-auto p-mb-auto p-shadow-12">
        <div class="pages-panel card p-d-flex p-flex-column custom-color">
            <div class="pages-header p-px-3 p-py-1">
                <h2>ПРИСТУП ЗАБРАЊЕН</h2>
            </div>
            <div class="card p-mt-3 p-px-6">
                <img src="assets/images/background/not-permitted.png" alt="" />
            </div>
            <div class="pages-detail p-pb-6">Затраженој страници није могуће приступити!</div>
            <button
                [label]="'Повратак на почетну страницу'"
                [routerLink]="'/pozorisni-muzej/izlozbe/pocetna'"
                type="button"
                pButton
                pRipple
            ></button>
        </div>
    </div>
</div>
