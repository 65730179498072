import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UtilityService } from 'src/modules/shared/services/utility.service';
import { ContactMessage } from '../../../shared/model/contact-message';
import { ContactService } from '../../services/contact.service';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    public contactForm: FormGroup;

    constructor(
        public fb: FormBuilder,
        public utilityService: UtilityService,
        public router: Router,
        public contactService: ContactService
    ) {
        this.contactForm = this.createForm();
    }

    createForm() {
        return this.fb.group({
            nameAndSurname: ['', [Validators.required, Validators.maxLength(80)]],
            email: ['', [Validators.required, Validators.email]],
            message: ['', [Validators.required, Validators.maxLength(500)]]
        });
    }

    ngOnInit(): void {}

    sendInformation(): void {
        const contactMessage: ContactMessage = {
            name_and_surname: this.contactForm.controls.nameAndSurname.value,
            email: this.contactForm.controls.email.value,
            message: this.contactForm.controls.message.value
        };
        this.contactService.submitContactMessage(contactMessage).subscribe(() => {
            const message: string = 'Успешно сте послали поруку. Биће вам одговорено у најкраћем року!';
            this.utilityService.displayToastrMessage(message);
            this.router.navigate(['/pozorisni-muzej/izlozbe/pocetna']);
        });
    }
}
