export class Login {
    static readonly type = '[Auth] Login';
    constructor(public payload: { username: string; password: string }) {}
}

export class RefreshToken {
    static readonly type = '[Auth] RefreshToken';
    constructor(public payload: { refreshToken: string }) {}
}

export class Logout {
    static readonly type = '[Auth] Logout';
}
