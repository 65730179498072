import { Component, Input, OnChanges } from '@angular/core';
import { Statistic } from 'src/modules/shared/model/statistic';
import { barChartOptions } from '../../config/bar-chart.options';
import { COLORS } from '../../constants/colors';
import { MONTHS } from '../../constants/months';
import { StatisticsService } from '../../services/statistics.service';

@Component({
    selector: 'app-visits-month',
    templateUrl: './visits-month.component.html',
    styleUrls: ['./visits-month.component.scss']
})
export class VisitsMonthComponent implements OnChanges {
    @Input() public visitStatistic: Statistic[];
    public barChartOptions: any;
    public monthlyChartData: any;

    constructor(public statisticsService: StatisticsService) {
        this.barChartOptions = barChartOptions();
        this.monthlyChartData = {};
        this.visitStatistic = [];
    }

    ngOnChanges(): void {
        this.initVisitStatistic();
    }

    initVisitStatistic(): void {
        const years: number[] = [...new Set(this.visitStatistic.map((item) => item.year))].sort();
        const dataset: any = [];

        for (let i = 0; i < years.length; i++) {
            const year: any = {
                label: years[i],
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                borderColor: COLORS[i],
                backgroundColor: COLORS[i],
                borderWidth: 2,
                fill: true
            };
            const statisticForYear: Statistic[] = this.visitStatistic.filter((item) => item.year === years[i]);
            statisticForYear.forEach((item) => (year.data[item.month - 1] = item.total));
            dataset.push(year);
        }

        this.monthlyChartData = {
            labels: MONTHS,
            datasets: dataset
        };
    }
}
