import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { AuthState } from '../states/auth/auth.state';
import { Logout, RefreshToken } from '../states/auth/auth.state.actions';

@Injectable({
    providedIn: 'root'
})
export class ErrorInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    constructor(private store: Store, private messageService: MessageService, private router: Router) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof ErrorEvent) {
                    this.messageService.add({ severity: 'error', summary: `Грешка: ${error.error.message}`, detail: '' });
                    return throwError(error.error.message);
                } else {
                    if (error.status === 401 && error.error.detail === 'Token expired!') {
                        return this.handle401Error(request, next, error);
                    }
                    this.messageService.add({ severity: 'error', summary: `${error.error.detail}`, detail: '' });
                    return throwError(error.message);
                }
            })
        );
    }

    handle401Error(request: HttpRequest<any>, next: HttpHandler, error: HttpErrorResponse) {
        const refreshTokenExpired: boolean = this.store.selectSnapshot(AuthState.isRefreshTokenExpired);

        if (!refreshTokenExpired) {
            if (!this.isRefreshing) {
                this.isRefreshing = true;
                this.refreshTokenSubject.next(null);

                const refreshToken = this.store.selectSnapshot(AuthState.refreshToken);
                if (refreshToken === null) {
                    return throwError(error.message);
                }

                return this.store.dispatch(new RefreshToken({ refreshToken })).pipe(
                    switchMap((state: any) => {
                        let token = '';
                        if (state.auth.token) {
                            token = state.auth.token;
                        }
                        this.isRefreshing = false;
                        this.refreshTokenSubject.next(token);
                        return next.handle(this.addToken(request, token));
                    })
                );
            } else {
                return this.refreshTokenSubject.pipe(
                    filter((token) => token !== null),
                    take(1),
                    switchMap((jwt) => next.handle(this.addToken(request, jwt)))
                );
            }
        } else {
            this.isRefreshing = false;
            this.store.dispatch(new Logout()).subscribe(() => this.router.navigate(['']));
            return throwError(error.message);
        }
    }

    private addToken(request: HttpRequest<any>, token: string) {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }
}
