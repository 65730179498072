<div class="p-grid p-d-flex p-jc-center">
    <div class="p-col-12 p-md-9">
        <ng-container *ngIf="exhibition; else noContent">
            <app-page-view [title]="exhibition.title" [subtitle]="exhibition.subtitle" [page]="page"></app-page-view>
        </ng-container>
        <ng-template #noContent>
            <div class="card content" style="background-color: #fae7cc">
                <h1 class="p-d-flex p-jc-center">Нема објављених изложби</h1>
            </div>
        </ng-template>
    </div>
    <ng-container *ngIf="exhibition">
        <div class="p-col-12 p-md-3">
            <div class="card">
                <p-galleria
                    [value]="exhibitions"
                    [responsiveOptions]="responsiveOptions"
                    (activeIndexChange)="visibleChange($event)"
                    [numVisible]="3"
                >
                    <ng-template pTemplate="item" let-item>
                        <img *ngIf="item.thumbnail" [src]="item.thumbnail.file" style="width: 100%; display: block" />
                        <img
                            *ngIf="!item.thumbnail"
                            src="/assets/images/thumbnail/placeholder-thumbnail.png"
                            style="width: 100%; display: block"
                        />
                    </ng-template>
                    <ng-template pTemplate="thumbnail" let-item>
                        <div class="p-grid p-nogutter p-justify-center">
                            <img *ngIf="item.thumbnail" [src]="item.thumbnail.file" style="display: block; max-height: 80px" />
                            <img
                                *ngIf="!item.thumbnail"
                                src="/assets/images/thumbnail/placeholder-thumbnail.png"
                                style="display: block; max-height: 80px"
                            />
                        </div>
                    </ng-template>
                </p-galleria>
                <hr />

                <p-dropdown
                    class="p-d-flex p-jc-center"
                    [autoDisplayFirst]="true"
                    [options]="languages"
                    [ngModel]="selectedLanguage"
                    (ngModelChange)="languageChagned($event)"
                    placeholder="Тип фајла"
                    styleClass="p-mb-2 p-mb-md-0"
                ></p-dropdown>
            </div>
            <p-accordion [multiple]="true">
                <p-accordionTab [selected]="false" header="Преузмите андроид апликацију и погледајте изложбу" style="margin-bottom: 0">
                    <div class="p-d-flex p-jc-center">
                        <qrcode
                            *ngIf="exhibition.thumbnail"
                            [qrdata]="exhibition.thumbnail.file"
                            [width]="160"
                            [errorCorrectionLevel]="'M'"
                            style="display: block"
                        ></qrcode>
                        <qrcode
                            *ngIf="!exhibition.thumbnail"
                            [qrdata]="'/assets/images/thumbnail/placeholder-thumbnail.png'"
                            [width]="160"
                            [errorCorrectionLevel]="'M'"
                            style="display: block"
                        ></qrcode>
                    </div>
                </p-accordionTab>
                <p-accordionTab [selected]="false" header="Преузмите иос апликацију и погледајте изложбу" style="margin-bottom: 0">
                    <div class="p-d-flex p-jc-center">
                        <qrcode
                            *ngIf="exhibition.thumbnail"
                            [qrdata]="exhibition.thumbnail.file"
                            [width]="160"
                            [errorCorrectionLevel]="'M'"
                            style="display: block"
                        ></qrcode>
                        <qrcode
                            *ngIf="!exhibition.thumbnail"
                            [qrdata]="'/assets/images/thumbnail/placeholder-thumbnail.png'"
                            [width]="160"
                            [errorCorrectionLevel]="'M'"
                            style="display: block"
                        ></qrcode>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
    </ng-container>
</div>
