export const COLORS: string[] = [
    '#caa775',
    '#dbbe95',
    '#c7a77a',
    '#af9064',
    '#91734a',
    '#af8f95',
    '#a07f85',
    '#97616c',
    '#994d5c',
    '#922C40'
];
