import { StateContext } from '@ngxs/store';
import * as _ from 'lodash';
import { Page } from 'src/modules/shared/model/page';
import { ExhibitionStateModel } from '../exhibition.state';
import { RemovePage } from '../exhibition.state.actions';

export const removePage = (ctx: StateContext<ExhibitionStateModel>, action: RemovePage) => {
    const state = ctx.getState();
    const pagesToIgnore: Page[] = _.cloneDeep(state.pages).filter((page) => page.language !== state.selectedLanguage);
    const pagesToReorder: Page[] = _.cloneDeep(state.pages)
        .filter((page) => page.language === state.selectedLanguage && page.page_number !== state.selectedPage)
        .sort((page1: Page, page2: Page) => {
            if (page1.page_number > page2.page_number) {
                return 1;
            }
            if (page1.page_number < page2.page_number) {
                return -1;
            }
            return 0;
        })
        .map((page, index) => {
            page.page_number = index + 1;
            return page;
        });
    const pages = [...pagesToIgnore, ...pagesToReorder];
    ctx.patchState({
        pages,
        selectedPage: 1,
        selectedSection: 0
    });
};
