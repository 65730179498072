import { StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { Page } from 'src/modules/shared/model/page';
import { ExhibitionStateModel } from '../exhibition.state';
import { ChangePageBackground } from '../exhibition.state.actions';

export const changePageBackground = (ctx: StateContext<ExhibitionStateModel>, action: ChangePageBackground) => {
    const state = ctx.getState();
    ctx.setState(
        patch({
            pages: updateItem<Page>(
                (item) => item?.page_number === state.selectedPage && item?.language === state.selectedLanguage,
                patch({
                    background_color: action.payload.backgroundColor,
                    background_image_portrait: action.payload.backgroundImagePortrait,
                    background_image_landscape: action.payload.backgroundImageLandscape
                })
            )
        })
    );
};
