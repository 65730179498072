import { StateContext } from '@ngxs/store';
import { ExhibitionStateModel } from '../exhibition.state';
import { NewExhibition } from '../exhibition.state.actions';

export const newExhibition = (ctx: StateContext<ExhibitionStateModel>, action: NewExhibition) => {
    ctx.patchState({
        loading: false,
        editable: false,
        selectedPage: 1,
        selectedLanguage: 'Српски',
        selectedSection: 0,
        ...action.payload.exhibition
    });
};
