import { StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { Page } from 'src/modules/shared/model/page';
import { ExhibitionStateModel } from '../exhibition.state';
import { ApplyTemplate } from '../exhibition.state.actions';

export const applyTemplate = (ctx: StateContext<ExhibitionStateModel>, action: ApplyTemplate) => {
    const state = ctx.getState();
    ctx.setState(
        patch({
            selectedSection: 0,
            pages: updateItem<Page>(
                (item) => item?.page_number === state.selectedPage && item?.language === state.selectedLanguage,
                patch({
                    row_number: action.payload.row_number,
                    column_number: action.payload.column_number,
                    sections: action.payload.sections
                })
            )
        })
    );
};
