import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../app.component';
import { Router, NavigationEnd, Event } from '@angular/router';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
    public style: any;

    constructor(public app: AppComponent, public router: Router) {
        this.style = {};
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                const url: string = event.urlAfterRedirects;
                if (url === '/pozorisni-muzej/informacije') {
                    this.style = { padding: '0 0 0 0' };
                } else {
                    this.style = {};
                }
            }
        });
    }

    ngOnInit(): void {}
}
