import { Component, Input, OnChanges } from '@angular/core';
import { Statistic } from 'src/modules/shared/model/statistic';
import { barChartOptions } from '../../config/bar-chart.options';
import { COLORS } from '../../constants/colors';
import { MONTHS } from '../../constants/months';

@Component({
    selector: 'app-downloads-month',
    templateUrl: './downloads-month.component.html',
    styleUrls: ['./downloads-month.component.scss']
})
export class DownloadsMonthComponent implements OnChanges {
    @Input() public downloadStatistic: Statistic[];
    public barChartOptions: any;
    public monthlyChartData: any;

    constructor() {
        this.barChartOptions = barChartOptions();
        this.monthlyChartData = {};
        this.downloadStatistic = [];
    }

    ngOnChanges(): void {
        this.initDownloadStatistic();
    }

    initDownloadStatistic(): void {
        const years: number[] = [...new Set(this.downloadStatistic.map((item) => item.year))].sort();
        const dataset: any = [];

        for (let i = 0; i < years.length; i++) {
            const year: any = {
                label: years[i],
                data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                borderColor: COLORS[i],
                backgroundColor: COLORS[i],
                borderWidth: 2,
                fill: true
            };
            const statisticForYear: Statistic[] = this.downloadStatistic.filter((item) => item.year === years[i]);
            statisticForYear.forEach((item) => (year.data[item.month - 1] = item.total));
            dataset.push(year);
        }

        this.monthlyChartData = {
            labels: MONTHS,
            datasets: dataset
        };
    }
}
