import { Component, OnInit, ViewChild } from '@angular/core';
import { PageContentComponent } from '../../components/page-content/page-content.component';

@Component({
    selector: 'app-exhibition-form',
    templateUrl: './exhibition-form.component.html',
    styleUrls: ['./exhibition-form.component.scss']
})
export class ExhibitionFormComponent implements OnInit {
    @ViewChild(PageContentComponent) pageContentComponent!: PageContentComponent;

    constructor() {}

    ngOnInit(): void {}

    addSection(sectionOrder: number) {
        this.pageContentComponent.addSection(sectionOrder);
    }
}
