import { StateContext } from '@ngxs/store';
import { tap } from 'rxjs/operators';
import { ExhibitionService } from 'src/modules/exhibition/services/exhibition.service';
import { ExhibitionStateModel } from '../exhibition.state';
import { LoadExhibition } from '../exhibition.state.actions';

export const loadExhibition = (exhibitionService: ExhibitionService) => (
    ctx: StateContext<ExhibitionStateModel>,
    action: LoadExhibition
) => {
    ctx.patchState({
        loading: true
    });
    return exhibitionService.getExhibitionDetail(action.payload.id).pipe(
        tap((ret) => {
            ctx.patchState({
                loading: false,
                editable: false,
                selectedLanguage: 'Српски',
                selectedPage: 1,
                selectedSection: 0,
                ...ret
            });
        })
    );
};
