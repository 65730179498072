<div class="outerDiv">
    <p-menubar>
        <ng-template pTemplate="end">
            <button pButton icon="pi pi-bars" (click)="toggleSidebar()"></button>
        </ng-template>
    </p-menubar>
    <p-sidebar [(visible)]="sidebarVisible" [showCloseIcon]="false" [style]="{ width: '20em' }" position="right">
        <div class="side-bar-page-container">
            <div *ngIf="exhibition" class="thumbnail-container">
                <img class="thumbnail" [src]="exhibition.thumbnail?.file" />
            </div>
            <div class="nav-page-container">
                <div
                    class="nav-page"
                    (click)="scrollToElement('page-' + (i + 1))"
                    [ngClass]="{ 'page-selected': currentPage === i }"
                    *ngFor="let page of exhibitionPages; let i = index"
                >
                    <i class="pi pi-file" style="font-size: 2rem"></i>Страница {{ page.page_number }}
                </div>
            </div>
        </div>
    </p-sidebar>
    <div class="scroller" #scrollContainer (scroll)="onScroll()">
        <div
            *ngFor="let page of exhibitionPages; let i = index"
            [id]="'page-' + (i + 1)"
            [ngStyle]="{
                'background-image': 'url(' + getPageBackground(page) + ')',
                'background-size': '100% 100%',
                'background-clip': 'border-box',
                'background-position': 'center',
                'background-attachment': 'fixed'
            }"
            class="page-container"
            #pageContainer
        >
            <div *ngFor="let section of page.sections | sort" class="section-container">
                <div *ngIf="section?.content?.content_type === 'image'" class="image-section" (click)="selectSection(section)">
                    <img src="{{ section?.content?.file?.file }}" />
                </div>
                <div
                    *ngIf="section?.content?.content_type === 'text'"
                    [ngStyle]="{
                        'background-image': 'url(' + getSectionBackground(section) + ')',
                        'background-position': 'center',
                        'background-repeat': 'no-repeat',
                        'background-size': '100% 100%',
                        '-webkit-border-radius': '15px',
                        '-moz-border-radius': '15px',
                        'border-radius': '15px'
                    }"
                    class="text-section"
                >
                    <img src="{{ getSectionBackground(section) }}" class="text-hidden" />
                    <div
                        class="text-content"
                        [ngStyle]="{ 'background-color': section.background_color }"
                        [innerHTML]="section?.content?.text_content"
                    ></div>
                </div>
                <div *ngIf="section?.content?.content_type === 'video'" class="video-section" (click)="selectSection(section)">
                    <img src="{{ 'https://img.youtube.com/vi/' + getVideoId(section.content?.file?.name) + '/maxresdefault.jpg' }}" />
                    <img class="play-button" src="assets/images/icons/play-button.svg" />
                </div>
                <div *ngIf="section?.content?.content_type === 'audio'" class="audio-section" (click)="selectSection(section)">
                    <img src="/assets/images/icons/audio_background.svg" />
                    <img class="play-button" src="assets/images/icons/play-button.svg" />
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="!loaded" class="spinner">
        <p-progressSpinner></p-progressSpinner>
    </div>
    <div class="image-overlay" @fade *ngIf="selectedSection !== null && selectedSection?.content?.content_type === 'image'">
        <button class="close-btn" (click)="closeOverlay()">X</button>
        <pinch-zoom overflow="visible" [limit-zoom]="50">
            <img class="pinch-zoom-img" src="{{ selectedSection?.content?.file?.file }}" />
        </pinch-zoom>
    </div>
    <div class="video-overlay" @fade *ngIf="selectedSection !== null && selectedSection?.content?.content_type === 'video'">
        <button class="close-btn" (click)="closeOverlay()">X</button>
        <youtube-player
            [playerVars]="{
                modestbranding: 1,
                showinfo: 0,
                controls: 1,
                rel: 0,
                iv_load_policy: 3,
                fs: 1
            }"
            [videoId]="getVideoId(selectedSection.content?.file?.name)"
            [width]="windowWidth"
            (ready)="youtubeReady($event)"
        >
            ></youtube-player
        >
    </div>
    <div class="audio-overlay" @fade *ngIf="selectedSection !== null && selectedSection?.content?.content_type === 'audio'">
        <button class="close-btn" (click)="closeOverlay()">X</button>
        <img src="assets/images/icons/audio-icon.svg" />
        <audio class="audio-player" controls autoplay>
            <source [src]="selectedSection.content?.file?.file" type="audio/mp3" />
        </audio>
    </div>
</div>
