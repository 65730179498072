import { StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import * as _ from 'lodash';
import { Page } from 'src/modules/shared/model/page';
import { Section } from 'src/modules/shared/model/section';
import { ExhibitionStateModel } from '../exhibition.state';
import { ChangeSectionOrder } from '../exhibition.state.actions';

export const changeSectionOrder = (ctx: StateContext<ExhibitionStateModel>, action: ChangeSectionOrder) => {
    const state = ctx.getState();
    const page: Page[] = _.cloneDeep(state.pages).filter(
        (item) => item.page_number === state.selectedPage && item.language === state.selectedLanguage
    );
    if (page !== undefined) {
        const sections: Section[] = [];
        for (const section of page[0].sections) {
            if (section.section_order === state.selectedSection) {
                section.section_order = action.payload.new_section_order;
            } else if (section.section_order === action.payload.new_section_order) {
                section.section_order = state.selectedSection;
            }
            sections.push({ ...section });
        }
        ctx.setState(
            patch({
                selectedSection: action.payload.new_section_order,
                pages: updateItem<Page>(
                    (item) => item?.page_number === state.selectedPage && item?.language === state.selectedLanguage,
                    patch({ sections })
                )
            })
        );
    }
};
