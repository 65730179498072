import { Component, OnInit, ViewChild } from '@angular/core';
import { ContactMessage } from 'src/modules/shared/model/contact-message';
import { ContactService } from '../../services/contact.service';
import { ConfirmationService, MessageService, SelectItem } from 'primeng/api';
import { ContactMessageResponse } from '../../../shared/model/contact-message-response';
import { Paginator } from 'primeng/paginator';
import { UtilityService } from 'src/modules/shared/services/utility.service';

@Component({
    selector: 'app-message-list',
    templateUrl: './message-list.component.html',
    styleUrls: ['./message-list.component.scss']
})
export class MessageListComponent implements OnInit {
    @ViewChild('paginator', { static: false }) public paginator!: Paginator;

    public columns: any;

    public sortingOptions: SelectItem[];
    public sorting: string;

    public searchValue: string;

    public messages: ContactMessage[];
    public totalRecords: number;
    public loading: boolean;

    public respondedMessageDialog: boolean;
    public respondedMessage: ContactMessageResponse;

    public sendEmailDialog: boolean;
    public messageToBeSent: any;

    constructor(
        public contactService: ContactService,
        public confirmationService: ConfirmationService,
        public messageService: MessageService,
        public utilityService: UtilityService
    ) {
        this.columns = [
            { field: 'nameAndSurname', header: 'Име и презиме' },
            { field: 'email', header: 'Емаил' },
            { field: 'message', header: 'Порука' },
            { field: 'submitted', header: 'Датум слања' },
            { field: 'replied', header: 'Датум одговора' }
        ];
        this.sortingOptions = [
            { label: 'Датум слања растуће', value: 'submitted' },
            { label: 'Датум слања опадајуће', value: '-submitted' },
            { label: 'Датум одговора растуће', value: 'replied' },
            { label: 'Датум одговора опадајуће', value: '-replied' },
            { label: 'Име и презиме растуће', value: 'name_and_surname' },
            { label: 'Име и презиме опадајуће', value: '-name_and_surname' },
            { label: 'Емаил растуће', value: 'email' },
            { label: 'Емаил опадајуће', value: '-email' }
        ];
        this.sorting = '-submitted';

        this.searchValue = '';

        this.messages = [];
        this.totalRecords = 0;
        this.loading = false;

        this.respondedMessageDialog = false;
        this.respondedMessage = {
            contact_message: 0,
            title: '',
            message: ''
        };

        this.sendEmailDialog = false;
        this.messageToBeSent = {
            id: 0,
            email: ''
        };
    }

    ngOnInit(): void {
        this.loadMessages(10, 1);
    }

    loadMessages(rows: number, page: number) {
        this.loading = true;
        this.contactService.getMessages(rows, page, this.sorting, this.searchValue).subscribe((ret) => {
            this.messages = ret.results;
            this.totalRecords = ret.count;
            this.loading = false;
        });
    }

    closeEmailDialog(visible: boolean) {
        this.sendEmailDialog = visible;
    }

    respond(message: ContactMessage): void {
        this.sendEmailDialog = true;
        this.messageToBeSent.id = message.id;
        this.messageToBeSent.email = message.email;
    }

    responded(response: any) {
        const message = this.messages.find((item) => item.id === response.id);
        if (message !== undefined) {
            message.replied = response.replied;
        }
    }

    showRespondedMessage(contactMessage: ContactMessage) {
        this.contactService.getResponse(contactMessage.id).subscribe((ret) => {
            this.respondedMessage = ret;
            this.respondedMessageDialog = true;
        });
    }

    closeRespondedMessageDialog() {
        this.respondedMessageDialog = false;
    }

    deleteMessage(message: ContactMessage): void {
        this.utilityService.displayConfirmationService('Да ли сте сигурни да желите да обришете поруку?', 'Брисање поруке', () => {
            this.contactService.deleteMessage(message.id).subscribe(() => {
                const page: number = this.paginator.getPage();
                this.utilityService.displayToastrMessage('Порука обрисана');
                if (this.totalRecords % 10 === 1) {
                    this.paginator.changePage(page - 1);
                } else {
                    this.paginator.changePage(page);
                }
            });
        });
    }

    customSort(event: any) {
        const page: number = this.paginator.getPage();
        this.sorting = event.value;
        this.loadMessages(10, page + 1);
    }

    paginate(event: any) {
        this.loadMessages(10, event.page + 1);
    }

    search() {
        this.paginator.changePage(0);
    }
}
