<div class="p-grid">
    <div class="p-col-12 p-md-9">
        <app-page-details (addSectionEvent)="addSection($event)"></app-page-details>
        <app-page-content></app-page-content>
    </div>
    <div class="p-col-12 p-md-3">
        <app-basic-commands></app-basic-commands>
        <p-accordion [multiple]="true">
            <app-exhibition-details></app-exhibition-details>
            <app-page-management></app-page-management>
        </p-accordion>
    </div>
</div>
