<p-dialog
    [visible]="true"
    (visibleChange)="close()"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [style]="{ width: '1200px' }"
    [closeOnEscape]="true"
>
    <p-progressBar mode="indeterminate" *ngIf="uploading"></p-progressBar>
    <p-fileUpload
        [customUpload]="true"
        [maxFileSize]="1000000000"
        [multiple]="true"
        [fileLimit]="15"
        [disabled]="uploading"
        (uploadHandler)="upload($event)"
        accept="image/*"
        chooseLabel="Изабери"
        uploadLabel="Пошаљи"
        cancelLabel="Уклони"
    ></p-fileUpload>
    <p-dataView #dv [value]="files" [rows]="6" [totalRecords]="totalRecords" [loading]="loading" layout="grid">
        <ng-template pTemplate="header">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                <span class="p-input-icon-left p-mb-2 p-mb-md-0">
                    <i class="pi pi-search"></i>
                    <input
                        [ngModel]="search"
                        (ngModelChange)="searchInputChanged($event)"
                        type="search"
                        pInputText
                        placeholder="Претрага по називу"
                    />
                </span>
            </div>
        </ng-template>
        <ng-template let-file pTemplate="gridItem">
            <div class="p-col-12 p-md-4">
                <div class="file-grid-item card">
                    <div class="file-grid-item-content">
                        <div [style]="{ height: imageProperties.height }" class="imageContainer">
                            <img width="100%" [src]="file.file" />
                        </div>
                        <div class="file-name">{{ file.name }}</div>
                        <div class="car-buttons p-mt-5">
                            <button
                                *ngIf="itemSelected(file.file) ? false : true"
                                (click)="addToGallery(file)"
                                class="p-button-info"
                                type="button"
                                label="Додај у галерију"
                                icon="pi pi-plus-circle"
                                pButton
                                pRipple
                            ></button>
                            <button
                                *ngIf="itemSelected(file.file) ? true : false"
                                (click)="removeFromGallery(file)"
                                class="p-button-info"
                                type="button"
                                label="Уклони из галерије"
                                icon="pi pi-plus-circle"
                                pButton
                                pRipple
                            ></button>
                            <button
                                style="margin-left: 10px"
                                (click)="deleteFile(file, file.id)"
                                class="p-button-info"
                                type="button"
                                label="Избриши слику"
                                icon="pi pi-plus-circle"
                                pButton
                                pRipple
                            ></button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <div>
                <p-paginator #paginator [rows]="6" [totalRecords]="totalRecords" (onPageChange)="paginate($event)"></p-paginator>
            </div>
            <div>
                <button
                    (click)="applyImagesToGallery()"
                    type="button"
                    class="p-button-info"
                    label="Сачувај измене"
                    pButton
                    pRipple
                ></button>
            </div>
        </ng-template>
    </p-dataView>
</p-dialog>
