import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Login } from 'src/modules/shared/model/login';
import { UtilityService } from 'src/modules/shared/services/utility.service';
import { Login as LoginAction } from 'src/modules/shared/states/auth/auth.state.actions';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public loginForm: FormGroup;

    constructor(public store: Store, public fb: FormBuilder, public router: Router) {
        this.loginForm = this.createForm();
    }

    ngOnInit(): void {}

    createForm() {
        return this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
    }

    login(): void {
        const login: Login = {
            username: this.loginForm.controls.email.value,
            password: this.loginForm.controls.password.value
        };
        this.store.dispatch(new LoginAction(login)).subscribe((ret) => {
            this.router.navigate(['/pozorisni-muzej/izlozbe']);
            this.loginForm.reset();
        });
    }
}
