import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Exhibition } from 'src/modules/shared/model/exhibition';
import { ExhibitionState, ExhibitionStateModel } from 'src/modules/shared/states/exhibition/exhibition.state';
import { ChangePublishProperty, UpdateExhibitionId } from '../../../shared/states/exhibition/exhibition.state.actions';
import { ExhibitionService } from '../../services/exhibition.service';

@Component({
    selector: 'app-basic-commands',
    templateUrl: './basic-commands.component.html',
    styleUrls: ['./basic-commands.component.scss']
})
export class BasicCommandsComponent implements OnInit {
    public published: boolean;
    public exhibitionId: number;

    constructor(
        private store: Store,
        public exhibitionService: ExhibitionService,
        public activatedRoute: ActivatedRoute,
        public router: Router,
        public confirmationService: ConfirmationService,
        public messageService: MessageService
    ) {
        this.published = this.store.selectSnapshot((state) => state.exhibition.published);
        this.exhibitionId = 0;
    }

    ngOnInit(): void {
        this.activatedRoute.params.subscribe((params) => {
            this.exhibitionId = params.id;
        });
    }

    deleteExhibition(): void {
        this.confirmationService.confirm({
            message: 'Да ли сте сигурни да желите да обришете изложбу?',
            header: 'Брисање изложбе',
            acceptLabel: 'Потврди',
            rejectLabel: 'Откажи',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.exhibitionService.deleteExhibition(this.exhibitionId).subscribe((ret) => {
                    this.messageService.add({ severity: 'success', summary: 'Successful', detail: 'Успешно обрисана изложба', life: 3000 });
                    this.router.navigate(['/pozorisni-muzej/izlozbe']);
                });
            }
        });
    }

    changePublishProperty(): void {
        const published: boolean = this.store.selectSnapshot((state) => state.exhibition.published);
        const dialogMessage = published ? 'Да ли желите на повучете изложбу?' : 'Да ли желите да објавите изложбу?';
        const header = published ? 'Повлачење изложбе' : 'Објављивање изложбе';
        const toastMessage = published ? 'Успешно повучена изложба' : 'Успешно објављена изложба';

        this.confirmationService.confirm({
            message: dialogMessage,
            header,
            acceptLabel: 'Потврди',
            rejectLabel: 'Откажи',
            icon: 'pi pi-exclamation-triangle',
            accept: () => {
                this.published = !published;
                this.messageService.add({ severity: 'success', summary: 'Successful', detail: toastMessage, life: 3000 });
                this.store.dispatch(new ChangePublishProperty({ published: !published }));
            }
        });
    }

    saveExhibition() {
        const exhibition: ExhibitionStateModel = this.store.selectSnapshot(ExhibitionState.exhibition);
        const exhibitionToSave: Exhibition = {
            id: exhibition.id,
            title: exhibition.title,
            subtitle: exhibition.subtitle,
            thumbnail: exhibition.thumbnail,
            note: exhibition.note,
            published: exhibition.published,
            pages: exhibition.pages,
            background_color: exhibition.background_color,
            background_image_portrait: exhibition.background_image_portrait,
            background_image_landscape: exhibition.background_image_landscape
        };
        this.exhibitionService.saveExhibition(exhibitionToSave).subscribe(
            (ret) => {
                this.messageService.add({ severity: 'success', summary: 'Успешно', detail: 'Успешно чување изложбе', life: 3000 });
                this.store.dispatch(
                    new UpdateExhibitionId({
                        id: ret.id
                    })
                );
            },
            (error) => {
                this.messageService.add({ severity: 'error', summary: 'Грешка', detail: 'Грешка на серверу', life: 3000 });
            }
        );
    }
}
