import { HttpParams, HttpClient } from '@angular/common/http';

export class UploadAdapter {
    constructor(private loader: any, public url: string, private http: HttpClient) {}
    //the uploadFile method use to upload image to your server
    uploadFile(file: any, url: string) {
        const formData: FormData = new FormData();
        const headers = new Headers();
        const name = file.name;
        formData.append('file', file);
        formData.append('name', name);
        formData.append('file_type', 'image');

        headers.append('Content-Type', 'multipart/form-data');
        headers.append('Accept', 'application/json');
        console.log('formData', formData);
        const params = new HttpParams();
        const options = {
            params,
            reportProgress: true
        };
        return this.http.post(url, formData, options);
    }

    upload() {
        const upload = new Promise((resolve, reject) => {
            this.loader.file.then((data: any) => {
                this.uploadFile(data, this.url).subscribe(
                    (result: any) => {
                        resolve({ default: result.file });
                    },
                    (error) => {
                        reject(data.msg);
                    }
                );
            });
        });
        return upload;
    }
    abort() {
        console.log('abort');
    }
}
