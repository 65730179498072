<div
    [ngClass]="{ 'p-input-filled': app.inputStyle === 'filled', 'p-ripple-disabled': !app.ripple, 'layout-rtl': app.isRTL }"
    [class]="'layout-menu-' + app.menuTheme + ' layout-topbar-' + app.topbarTheme"
    class="layout-wrapper"
>
    <app-navbar></app-navbar>

    <div class="layout-main">
        <div class="layout-content" [ngStyle]="style">
            <router-outlet></router-outlet>
        </div>
        <app-footer></app-footer>
    </div>
</div>
