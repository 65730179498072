export const pieChartOptions = () => {
    const textColor = getComputedStyle(document.body).getPropertyValue('--text-color') || 'rgba(0, 0, 0, 0.87)';
    const fontFamily = getComputedStyle(document.body).getPropertyValue('--font-family');
    return {
        legend: {
            display: true,
            labels: {
                fontFamily,
                fontColor: textColor
            }
        },
        responsive: true
    };
};
