import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedResponse } from 'src/modules/shared/model/paginated-response';
import { File } from '../../shared/model/file';

@Injectable({
    providedIn: 'root'
})
export class FileService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(public http: HttpClient) {}

    getFiles(pageSize: number, page: number, file_type: string, name: string): Observable<PaginatedResponse<File>> {
        return this.http.get<PaginatedResponse<File>>(
            `api/file/?page=${page}&page_size=${pageSize}&file_type=${file_type}&search=${name}&ordering=-uploaded`,
            {
                headers: this.headers
            }
        );
    }

    uploadFile(formData: FormData): Observable<any> {
        return this.http.post('/api/file/upload/', formData, {
            reportProgress: true,
            observe: 'events'
        });
    }

    uploadImage(formData: FormData) {
        return new Promise((resolve, reject) =>
            this.http
                .post('/api/file/upload/', formData)
                .toPromise()
                .then((result: any) => {
                    resolve(result.file);
                })
                .catch((error) => {
                    console.log(error);
                })
        );
    }

    deleteFile(fileId: number): Observable<any> {
        return this.http.delete(`api/file/${fileId}`);
    }
}
