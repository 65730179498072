import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Page } from 'src/modules/shared/model/page';
import { ExhibitionState } from 'src/modules/shared/states/exhibition/exhibition.state';
import * as QRCode from 'qrcode';

interface QrSize {
    label: string;
    width: number;
    height: number;
}

@Component({
    selector: 'app-page-qr-code',
    templateUrl: './page-qr-code.component.html',
    styleUrls: ['./page-qr-code.component.scss']
})
export class PageQrCodeComponent implements OnInit {
    public page$!: Observable<Page | undefined>;
    public url: string | null = null;
    public printSizes: QrSize[] = [
        { label: 'Мала', width: 240, height: 240 },
        { label: 'Средња', width: 480, height: 480 },
        { label: 'Велика', width: 720, height: 720 }
    ];
    public selectedPrintSize: QrSize = this.printSizes[0];
    private exhibitionId: number | null = null;

    constructor(public store: Store) {}

    ngOnInit(): void {
        this.exhibitionId = this.store.selectSnapshot((state) => state.exhibition.id);
        this.store.select(ExhibitionState.page).subscribe((page) => {
            if (page) {
                this.url = `${window.origin}/mobilni-prikaz?izlozba=${this.exhibitionId}&stranica=${page.page_number}`;
            }
        });
    }

    async printQrCode(): Promise<void> {
        if (!this.url) {
            return;
        }

        try {
            const qrDataUrl = await QRCode.toDataURL(this.url, {
                width: this.selectedPrintSize.width,
                margin: 1,
                errorCorrectionLevel: 'M'
            });

            const printWindow = window.open('', '_blank');
            if (printWindow) {
                printWindow.document.write(`
                    <html>
                        <head>
                            <title>Print QR Code</title>
                            <style>
                                @page {
                                    size: A4;
                                    margin: 0;
                                }
                                @media print {
                                    body { 
                                        display: flex; 
                                        justify-content: center; 
                                        align-items: center; 
                                        height: 297mm; 
                                        width: 210mm;
                                        margin: 0; 
                                        padding: 0;
                                        background: white; 
                                    }
                                    img {
                                        max-width: 100%;
                                        height: auto;
                                    }
                                }
                                body { 
                                    display: flex; 
                                    justify-content: center; 
                                    align-items: center; 
                                    height: 297mm; 
                                    width: 210mm;
                                    margin: 0; 
                                    padding: 0;
                                    background: white; 
                                }
                                img {
                                    max-width: 100%;
                                    height: auto;
                                }
                            </style>
                        </head>
                        <body>
                            <img src="${qrDataUrl}" alt="QR Code">
                        </body>
                    </html>
                `);
                printWindow.document.close();
                printWindow.onload = () => {
                    printWindow.print();
                };
            }
        } catch (error) {
            console.error('Error generating QR code:', error);
        }
    }
}
