import { StateContext } from '@ngxs/store';
import { append, patch, updateItem } from '@ngxs/store/operators';
import { Page } from 'src/modules/shared/model/page';
import { ExhibitionStateModel } from '../exhibition.state';
import { AddNewSection } from '../exhibition.state.actions';

export const addNewSection = (ctx: StateContext<ExhibitionStateModel>, action: AddNewSection) => {
    const state = ctx.getState();
    ctx.setState(
        patch({
            selectedSection: action.payload.section.section_order,
            pages: updateItem<Page>(
                (item) => item?.page_number === state.selectedPage && item?.language === state.selectedLanguage,
                patch({ sections: append([action.payload.section]) })
            )
        })
    );
};
