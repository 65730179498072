import { EventEmitter, Input } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UtilityService } from 'src/modules/shared/services/utility.service';
import { ExhibitionState } from 'src/modules/shared/states/exhibition/exhibition.state';
import {
    ChangeBackground,
    ChangePageBackground,
    ChangePageBackgroundForAll,
    ChangeSectionBackground
} from 'src/modules/shared/states/exhibition/exhibition.state.actions';
import { File } from '../../../shared/model/file';

@Component({
    selector: 'app-background-dialog',
    templateUrl: './background-dialog.component.html',
    styleUrls: ['./background-dialog.component.scss']
})
export class BackgroundDialogComponent implements OnInit {
    @Input() public backgroundImageFor: string;
    @Output() public closeDialog: EventEmitter<void>;

    public backgroundImagePortrait$!: Observable<File | null | undefined>;
    public backgroundImageLandscape$!: Observable<File | null | undefined>;
    public backgroundColor$!: Observable<string | undefined>;

    public selectedImagePortrait: File | null | undefined;
    public selectedImageLandscape: File | null | undefined;
    public selectedColor: string | null | undefined;

    public selectOptions: any[];
    public selectedOption: string;

    public uploadDialogOpened: boolean;

    constructor(public store: Store, public utilityService: UtilityService) {
        this.selectOptions = [
            { label: 'Портрет', value: 'portrait' },
            { label: 'Пејзаж', value: 'landscape' }
        ];
        this.selectedOption = 'portrait';
        this.selectedImagePortrait = null;
        this.selectedImageLandscape = null;
        this.selectedColor = null;
        this.uploadDialogOpened = false;
        this.backgroundImageFor = 'exhibition';
        this.closeDialog = new EventEmitter();
    }

    ngOnInit(): void {
        if (this.backgroundImageFor === 'exhibition') {
            this.selectedImagePortrait = this.store.selectSnapshot((state) => state.exhibition.background_image_portrait);
            this.selectedImageLandscape = this.store.selectSnapshot((state) => state.exhibition.background_image_landscape);
            this.backgroundColor$ = this.store.selectSnapshot((state) => state.exhibition.background_color);
        } else if (this.backgroundImageFor === 'page') {
            this.selectedImagePortrait = this.store.selectSnapshot(ExhibitionState.pageBackgroundImagePortrait);
            this.selectedImageLandscape = this.store.selectSnapshot(ExhibitionState.pageBackgroundImageLandscape);
            this.selectedColor = this.store.selectSnapshot(ExhibitionState.pageBackgroundImageColor);
        } else if (this.backgroundImageFor === 'section') {
            this.selectedImagePortrait = this.store.selectSnapshot(ExhibitionState.sectionBackgroundImagePortrait);
            this.selectedImageLandscape = this.store.selectSnapshot(ExhibitionState.sectionBackgroundImageLandscape);
            this.selectedColor = this.store.selectSnapshot(ExhibitionState.sectionBackgroundColor);
        }
    }

    orientationChanged(orientation: string) {
        this.selectedOption = orientation;
    }

    openUploadDialog() {
        this.uploadDialogOpened = true;
    }

    closeUploadDialog() {
        this.uploadDialogOpened = false;
    }

    changeBackground(fileSelected: File) {
        this.closeUploadDialog();
        this.setImage(fileSelected);
    }

    deleteBackground() {
        this.setImage(null);
    }

    changeColor(color: string): void {
        this.setColor(color);
    }

    setColorToTransparent() {
        this.setColor(null);
    }

    changeBackgroundForExhibition() {
        this.store.dispatch(
            new ChangeBackground({
                backgroundColor: this.selectedColor || null,
                backgroundImagePortrait: this.selectedImagePortrait || null,
                backgroundImageLandscape: this.selectedImageLandscape || null
            })
        );
    }

    changePageBackground() {
        this.store.dispatch(
            new ChangePageBackground({
                backgroundColor: this.selectedColor || null,
                backgroundImagePortrait: this.selectedImagePortrait || null,
                backgroundImageLandscape: this.selectedImageLandscape || null
            })
        );
    }

    changePageBackgroundForAll(background: any) {
        this.store.dispatch(
            new ChangePageBackgroundForAll({
                backgroundColor: background.color,
                backgroundImagePortrait: background.imagePortrait,
                backgroundImageLandscape: background.imageLandscape
            })
        );
    }

    changeSectionBackground() {
        this.store.dispatch(
            new ChangeSectionBackground({
                backgroundColor: this.selectedColor || null,
                backgroundImagePortrait: this.selectedImagePortrait || null,
                backgroundImageLandscape: this.selectedImageLandscape || null
            })
        );
    }

    setColor(color: any) {
        this.selectedColor = color;
    }

    setImage(fileSelected: any) {
        if (this.selectedOption === 'portrait') {
            this.selectedImagePortrait = fileSelected;
        } else {
            this.selectedImageLandscape = fileSelected;
        }
    }

    save() {
        this.close();
        if (this.backgroundImageFor === 'exhibition') {
            this.changeBackgroundForExhibition();
        } else if (this.backgroundImageFor === 'page') {
            this.changePageBackground();
        } else if (this.backgroundImageFor === 'section') {
            this.changeSectionBackground();
        }
    }

    saveForAll() {
        if (this.backgroundImageFor === 'page') {
            this.changePageBackgroundForAll({
                color: this.selectedColor,
                imagePortrait: this.selectedImagePortrait,
                imageLandscape: this.selectedImageLandscape
            });
        } else {
            this.utilityService.displayToastrMessage('Измене се могу применити само за странице', 'Грешка', 'error');
        }
    }

    close() {
        this.closeDialog.emit();
    }
}
