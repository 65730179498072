import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ContactMessage } from '../../shared/model/contact-message';
import { Observable } from 'rxjs';
import { PaginatedResponse } from '../../shared/model/paginated-response';
import { ContactMessageResponse } from '../../shared/model/contact-message-response';

@Injectable({
    providedIn: 'root'
})
export class ContactService {
    private headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    constructor(public http: HttpClient) {}

    submitContactMessage(contactMessage: ContactMessage): Observable<ContactMessage> {
        return this.http.post<ContactMessage>('api/contact/create/', contactMessage, { headers: this.headers });
    }

    getMessages(pageSize: number, page: number, order: string, search: string): Observable<PaginatedResponse<ContactMessage>> {
        return this.http.get<PaginatedResponse<ContactMessage>>(
            `api/contact/?page=${page}&page_size=${pageSize}&ordering=${order}&search=${search}`,
            {
                headers: this.headers
            }
        );
    }

    deleteMessage(messageId: number | undefined): Observable<any> {
        return this.http.delete(`api/contact/${messageId}/delete/`);
    }

    sendResponse(contactMessageResponse: ContactMessageResponse): Observable<ContactMessageResponse> {
        return this.http.post<ContactMessageResponse>('api/contact/response/', contactMessageResponse, {
            headers: this.headers
        });
    }

    getResponse(messageId: number | undefined): Observable<ContactMessageResponse> {
        return this.http.get<ContactMessageResponse>(`api/contact/response/${messageId}/`);
    }
}
