import { Component, OnInit, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { ChangeTitle, ChangeNote, ChangeThumbnail, ChangeSubtitle } from '../../../shared/states/exhibition/exhibition.state.actions';
import { File } from '../../../shared/model/file';
import * as Editor from '../../../../assets/CKEditor5/ckeditor';
import { ConfigTitleAndSubtitle } from '../../config/editor.config';

@Component({
    selector: 'app-exhibition-details',
    templateUrl: './exhibition-details.component.html',
    styleUrls: ['./exhibition-details.component.scss']
})
export class ExhibitionDetailsComponent implements OnInit {
    @ViewChild('titleEditor') titleEditor!: any;
    @ViewChild('subtitleEditor') subtitleEditor!: any;

    public title$: Observable<string>;
    public subtitle$: Observable<string>;
    public note$: Observable<string>;
    public thumbnail$: Observable<File>;
    public color$: Observable<string>;

    public uploadThumbnailDialog: boolean;
    public backgroundDialog: boolean;

    public editorTitle: any;
    public editorSubtitle: any;
    public configTitleAndSubtitle: any;

    constructor(public store: Store) {
        this.title$ = this.store.select((state) => state.exhibition.title);
        this.subtitle$ = this.store.select((state) => state.exhibition.subtitle);
        this.note$ = this.store.select((state) => state.exhibition.note);
        this.thumbnail$ = this.store.select((state) => state.exhibition.thumbnail);
        this.color$ = this.store.select((state) => state.exhibition.background_color);

        this.uploadThumbnailDialog = false;
        this.backgroundDialog = false;

        this.editorTitle = Editor;
        this.editorSubtitle = Editor;
        this.configTitleAndSubtitle = ConfigTitleAndSubtitle;
    }

    ngOnInit(): void {}

    updateTitle(title: string): void {
        this.store.dispatch(new ChangeTitle({ title }));
    }

    updateSubtitle(subtitle: string) {
        this.store.dispatch(new ChangeSubtitle({ subtitle }));
    }

    /*updateTitle(): void {
        this.store.dispatch(new ChangeTitle({ title: this.titleEditor.editorInstance.getData() }));
    }

    updateSubtitle() {
        this.store.dispatch(new ChangeSubtitle({ subtitle: this.subtitleEditor.editorInstance.getData() }));
    }*/

    updateNote(note: string): void {
        this.store.dispatch(new ChangeNote({ note }));
    }

    changeThumbnailContent(fileSelected: File) {
        this.closeUploadDialog();
        this.store.dispatch(
            new ChangeThumbnail({
                thumbnail: fileSelected
            })
        );
    }

    uploadThumbnail() {
        this.uploadThumbnailDialog = true;
    }

    closeUploadDialog() {
        this.uploadThumbnailDialog = false;
    }

    openBackgroundDialog() {
        this.backgroundDialog = true;
    }

    closeBackgroundDialog() {
        this.backgroundDialog = false;
    }
}
