import { StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { Page } from 'src/modules/shared/model/page';
import { ExhibitionStateModel } from '../exhibition.state';
import { ConvertPageToGallery } from '../exhibition.state.actions';

export const convertPageToGallery = (ctx: StateContext<ExhibitionStateModel>, action: ConvertPageToGallery) => {
    const state = ctx.getState();
    ctx.setState(
        patch({
            selectedSection: 0,
            pages: updateItem<Page>(
                (item) => item?.page_number === state.selectedPage && item?.language === state.selectedLanguage,
                patch({ gallery: true })
            )
        })
    );
};
