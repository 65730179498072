import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { LoginPayload } from '../../model/login-payload';
import { AuthService } from '../../services/auth.service';
import { tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Login, Logout, RefreshToken } from './auth.state.actions';

export interface AuthStateModel {
    token: string | null;
    refreshToken: string | null;
    email: string | null;
}

@State<AuthStateModel>({
    name: 'auth',
    defaults: {
        token: null,
        refreshToken: null,
        email: null
    }
})
@Injectable()
export class AuthState {
    constructor(private authService: AuthService) {}

    @Selector()
    static token(state: AuthStateModel): string | null {
        return state.token;
    }

    @Selector()
    static refreshToken(state: AuthStateModel): string | null {
        return state.refreshToken;
    }

    @Selector()
    static isAuthenticated(state: AuthStateModel): boolean {
        return !!state.token;
    }

    @Selector()
    static isRefreshTokenExpired(state: AuthStateModel): boolean {
        const jwt: JwtHelperService = new JwtHelperService();
        if (state.refreshToken === null) {
            return false;
        }
        return jwt.isTokenExpired(state.refreshToken);
    }

    @Action(Login)
    login(ctx: StateContext<AuthStateModel>, action: Login) {
        return this.authService.login(action.payload).pipe(
            tap((result: LoginPayload) => {
                ctx.patchState({
                    token: result.access,
                    refreshToken: result.refresh,
                    email: action.payload.username
                });
            })
        );
    }

    @Action(RefreshToken)
    refreshToken(ctx: StateContext<AuthStateModel>, action: RefreshToken) {
        const helper: JwtHelperService = new JwtHelperService();
        return this.authService.refreshToken(action.payload.refreshToken).pipe(
            tap((result: LoginPayload) => {
                ctx.patchState({
                    token: result.access,
                    refreshToken: result.refresh,
                    email: helper.decodeToken(result.access).email
                });
            })
        );
    }

    @Action(Logout)
    logout(ctx: StateContext<AuthStateModel>) {
        ctx.setState({
            token: null,
            refreshToken: null,
            email: null
        });
    }
}
