import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Page } from 'src/modules/shared/model/page';
import { Section } from 'src/modules/shared/model/section';

@Component({
    selector: 'app-page-view',
    templateUrl: './page-view.component.html',
    styleUrls: ['./page-view.component.scss']
})
export class PageViewComponent implements OnInit, OnChanges {
    @Input() title: string;
    @Input() subtitle: string;
    @Input() page!: Page;

    public sections: any[];

    constructor() {
        this.title = '';
        this.subtitle = '';

        this.sections = [];
    }

    ngOnInit(): void {}

    ngOnChanges(): void {
        this.sections = this.generatePositions(this.page);
    }

    generatePositions(page: Page | undefined): any[] {
        if (!page) {
            return [];
        }
        const sections: any[] = [];
        for (const section of page.sections) {
            sections.push({
                ...section,
                position: {
                    left: section.x_coordinate,
                    top: section.y_coordinate,
                    width: section.width,
                    height: section.height
                }
            });
        }
        return sections;
    }

    getSectionInOrderForGaller(sections: Section[]) {
        const sectionsToSort = [...sections];
        return sectionsToSort.sort((a: Section, b: Section) => (a.section_order > b.section_order ? 1 : -1));
    }

    getVideoId(youtubeUrl: string) {
        let retVal: RegExpMatchArray | null = null;
        const rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
        retVal = youtubeUrl.match(rx);
        if (retVal !== null) {
            return retVal[1];
        }
        return '';
    }
}
