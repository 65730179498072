import { StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { Page } from 'src/modules/shared/model/page';
import { Section } from 'src/modules/shared/model/section';
import { ExhibitionStateModel } from '../exhibition.state';
import { ChangeSectionBackground } from '../exhibition.state.actions';

export const changeSectionBackground = (ctx: StateContext<ExhibitionStateModel>, action: ChangeSectionBackground) => {
    const state = ctx.getState();
    ctx.setState(
        patch({
            pages: updateItem<Page>(
                (item) => item?.page_number === state.selectedPage && item?.language === state.selectedLanguage,
                patch({
                    sections: updateItem<Section>(
                        (item) => item?.section_order === state.selectedSection,
                        patch({
                            background_color: action.payload.backgroundColor,
                            background_image_portrait: action.payload.backgroundImagePortrait,
                            background_image_landscape: action.payload.backgroundImageLandscape
                        })
                    )
                })
            )
        })
    );
};
