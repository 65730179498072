import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { SelectItem } from 'primeng/api';
import { Page } from 'src/modules/shared/model/page';
import { ExhibitionState } from 'src/modules/shared/states/exhibition/exhibition.state';
import { ExhibitionService } from '../../services/exhibition.service';

@Component({
    selector: 'app-exhibition-view',
    templateUrl: './exhibition-view.component.html',
    styleUrls: ['./exhibition-view.component.scss']
})
export class ExhibitionViewComponent implements OnInit {
    public title: string;
    public subtitle: string;
    public pageNumber: number;
    public page!: Page;

    public languages: SelectItem[];
    public selectedLanguage: string;

    constructor(public store: Store, public exhibitionService: ExhibitionService) {
        this.title = this.store.selectSnapshot((state) => state.exhibition.title);
        this.subtitle = this.store.selectSnapshot((state) => state.exhibition.subtitle);
        this.pageNumber = 0;

        this.languages = [];
        this.selectedLanguage = '';
    }

    ngOnInit(): void {
        this.exhibitionService.getLanguages().subscribe((ret) => {
            this.languages = ret.map((item) => ({
                label: item.name,
                value: item.name
            }));

            this.pageNumber = this.store.selectSnapshot((state) =>
                state.exhibition.pages.filter((page: Page) => page.language === ret[0].name)
            ).length;
            this.page = this.store.selectSnapshot(
                (state) => state.exhibition.pages.filter((page: Page) => page.page_number === 1 && page.language === ret[0].name)[0]
            );

            this.selectedLanguage = ret[0].name;
        });
    }

    selectedLanguageChagned(selectedLanguage: string) {
        this.selectedLanguage = selectedLanguage;
        this.page = this.store.selectSnapshot(
            (state) => state.exhibition.pages.filter((page: Page) => page.page_number === 1 && page.language === selectedLanguage)[0]
        );
        this.pageNumber = this.store.selectSnapshot((state) =>
            state.exhibition.pages.filter((page: Page) => page.language === selectedLanguage)
        ).length;
    }

    paginate(event: any) {
        this.page = this.store.selectSnapshot(
            (state) =>
                state.exhibition.pages.filter(
                    (page: Page) => page.page_number === event.page + 1 && page.language === this.selectedLanguage
                )[0]
        );
    }
}
