import { Content } from '../../model/content';
import { Section } from '../../model/section';
import { File } from '../../model/file';
import { TemplateSection } from '../../model/template-section';
import { Exhibition } from '../../model/exhibition';

export class LoadExhibition {
    static readonly type = '[Exhibition] LoadExhibition';
    constructor(public payload: { id: number }) {}
}

export class ChangePublishProperty {
    static readonly type = '[Exhibition] ChangePublishProperty';
    constructor(public payload: { published: boolean }) {}
}

export class ChangeEditMode {
    static readonly type = '[Exhibition] ChangeEditMode';
    constructor(public payload: { editable: boolean }) {}
}

export class ChangeTitle {
    static readonly type = '[Exhibition] ChangeTitle';
    constructor(public payload: { title: string }) {}
}

export class ChangeSubtitle {
    static readonly type = '[Exhibition] ChangeSubtitle';
    constructor(public payload: { subtitle: string }) {}
}

export class ChangeNote {
    static readonly type = '[Exhibition] ChangePostalCode';
    constructor(public payload: { note: string }) {}
}

export class ChangeSelectedPage {
    static readonly type = '[Exhibition] ChangeSelectedPage';
    constructor(public payload: { selectedPage: number }) {}
}

export class ChangeSelectedLanguage {
    static readonly type = '[Exhibition] ChangeSelectedLanguage';
    constructor(public payload: { selectedLanguage: string }) {}
}

export class AddPage {
    static readonly type = '[Exhibition] AddPage';
    constructor(public payload: { page: any }) {}
}

export class RemovePage {
    static readonly type = '[Exhibition] RemovePage';
    constructor() {}
}

export class ChangePageLanguage {
    static readonly type = '[Exhibition] ChangePageLanguage';
    constructor(public payload: { pageLanguage: string }) {}
}

export class MakeIntroductoryPage {
    static readonly type = '[Exhibition] MakeIntroductoryPage';
    constructor(public payload: { introductory: boolean }) {}
}

export class ChangePageRowNumber {
    static readonly type = '[Exhibition] ChangePageRowNumber';
    constructor(public payload: { rowNumber: number }) {}
}

export class ChangePageColumnNumber {
    static readonly type = '[Exhibition] ChangePageColumnNumber';
    constructor(public payload: { columnNumber: number }) {}
}

export class AddNewSection {
    static readonly type = '[Exhibition] AddNewSection';
    constructor(public payload: { section: Section }) {}
}

export class RemoveContentFromAllSections {
    static readonly type = '[Exhibition] RemoveContentFromAllSections';
    constructor(public payload: { sections: Section[] }) {}
}

export class RemoveSection {
    static readonly type = '[Exhibition] RemoveSection';
    constructor(public payload: { sections: Section[] }) {}
}

export class RemoveSectionContent {
    static readonly type = '[Exhibition] RemoveSectionContent';
    constructor(public payload: { sections: Section[] }) {}
}

export class ChangeSelectedSection {
    static readonly type = '[Exhibition] ChangeSelectedSection';
    constructor(public payload: { selectedSection: number }) {}
}

export class ChangeSectionContent {
    static readonly type = '[Exhibition] ChangeSectionContent';
    constructor(public payload: { content: Content | null }) {}
}

export class ChangeThumbnail {
    static readonly type = '[Exhibition] ChangeThumbnail';
    constructor(public payload: { thumbnail: File }) {}
}

export class ApplyTemplate {
    static readonly type = '[Exhibition] ApplyTemplate';
    constructor(public payload: { row_number: number; column_number: number; sections: TemplateSection[] }) {}
}

export class ChangePageOrder {
    static readonly type = '[Exhibition] ChangePageOrder';
    constructor(public payload: { new_page_number: number }) {}
}

export class ChangeSectionCoordinates {
    static readonly type = '[Exhibition] ChangeSectionCoordinates';
    constructor(public payload: { section_order: number; top: number; left: number; width: number; height: number }) {}
}

export class ChangeSectionOrder {
    static readonly type = '[Exhibition] ChangeSectionOrder';
    constructor(public payload: { new_section_order: number }) {}
}

export class NewExhibition {
    static readonly type = '[Exhibition] NewExhibition';
    constructor(public payload: { exhibition: Exhibition }) {}
}

export class ChangeBackground {
    static readonly type = '[Exhibition] ChangeBackground';
    constructor(
        public payload: { backgroundColor: string | null; backgroundImagePortrait: File | null; backgroundImageLandscape: File | null }
    ) {}
}

export class ChangePageBackground {
    static readonly type = '[Exhibition] ChangePageBackground';
    constructor(
        public payload: { backgroundColor: string | null; backgroundImagePortrait: File | null; backgroundImageLandscape: File | null }
    ) {}
}

export class UpdateExhibitionId {
    static readonly type = '[Exhibition] UpdateExhibitionId';
    constructor(public payload: { id: number }) {}
}

export class ChangeSectionBackground {
    static readonly type = '[Exhibition] ChangeSectionBackground';
    constructor(
        public payload: { backgroundColor: string | null; backgroundImagePortrait: File | null; backgroundImageLandscape: File | null }
    ) {}
}

export class ChangePageBackgroundForAll {
    static readonly type = '[Exhibition] ChangePageBackgroundForAll';
    constructor(
        public payload: { backgroundImageLandscape: File | null; backgroundImagePortrait: File | null; backgroundColor: string | null }
    ) {}
}

export class ConvertPageToGallery {
    static readonly type = '[Exhibition] ConvertPageToGallery';
    constructor() {}
}

export class RevertPageFromGallery {
    static readonly type = '[Exhibition] ChangeSectionBackgroundImagePortrait';
    constructor(public payload: { sections: Section[] }) {}
}

export class AddSectionsToGallery {
    static readonly type = '[Exhibition] AddSectionsToGallery';
    constructor(public payload: { sections: Section[] }) {}
}
