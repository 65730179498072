<p-dialog
    [visible]="true"
    [closable]="true"
    [modal]="true"
    [draggable]="false"
    [resizable]="false"
    [style]="{ width: '800px' }"
    [closeOnEscape]="true"
    (visibleChange)="close()"
>
    <p-panel>
        <ng-template pTemplate="header">
            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between inputHeader">
                <p-dropdown
                    placeholder="Оријентација"
                    [options]="selectOptions"
                    [ngModel]="selectedOption"
                    (ngModelChange)="orientationChanged($event)"
                ></p-dropdown>
                <div class="inputColor">
                    <span class="inputColorLabel">Боја позадине:</span>
                    <input style="height: 35px" [(ngModel)]="selectedColor" type="color" />
                    <button
                        (click)="setColorToTransparent()"
                        type="button"
                        class="p-button-info removeColor"
                        icon="pi pi-trash"
                        label="Уклони боју позадине"
                        pButton
                        pRipple
                    ></button>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="content">
            <div *ngIf="selectedOption === 'portrait'" class="portrait">
                <ng-container *ngIf="selectedImagePortrait; else backgroundImagePortrait">
                    <img [src]="selectedImagePortrait.file" />
                </ng-container>
                <ng-template #backgroundImagePortrait>
                    <img src="/assets/images/thumbnail/placeholder-thumbnail-portrait.png" />
                </ng-template>
            </div>
            <div *ngIf="selectedOption === 'landscape'" class="landscape">
                <ng-container *ngIf="selectedImageLandscape; else backgroundImageLandscape">
                    <img [src]="selectedImageLandscape.file" />
                </ng-container>
                <ng-template #backgroundImageLandscape>
                    <img src="/assets/images/thumbnail/placeholder-thumbnail-portrait.png" />
                </ng-template>
            </div>
            <div class="controls">
                <button
                    (click)="openUploadDialog()"
                    type="button"
                    class="p-button-success p-button-rounded p-mr-2"
                    icon="pi pi-plus"
                    pButton
                    pRipple
                ></button>
                <button
                    (click)="deleteBackground()"
                    type="button"
                    class="p-button-danger p-button-rounded"
                    icon="pi pi-trash"
                    pButton
                    pRipple
                ></button>
            </div>
        </ng-template>
        <ng-template pTemplate="footer">
            <button
                *ngIf="backgroundImageFor === 'page'"
                (click)="saveForAll()"
                type="button"
                class="p-button-info saveForAll"
                label="Сачувај измене и примени на све странице"
                pButton
                pRipple
            ></button>
            <button (click)="save()" type="button" class="p-button-info" label="Сачувај измене" pButton pRipple></button>
        </ng-template>
    </p-panel>
</p-dialog>

<app-upload-dialog
    *ngIf="uploadDialogOpened"
    [selectedOption]="'image'"
    [selectionDisabled]="true"
    (fileSelected)="changeBackground($event)"
    (closeDialog)="closeUploadDialog()"
></app-upload-dialog>
