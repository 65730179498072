import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Exhibition } from 'src/modules/shared/model/exhibition';
import { Page } from 'src/modules/shared/model/page';
import { responsiveOptions } from '../../config/responsive-galeria.config';
import { ExhibitionService } from '../../services/exhibition.service';

@Component({
    selector: 'app-exhibition-front-page',
    templateUrl: './exhibition-front-page.component.html',
    styleUrls: ['./exhibition-front-page.component.scss']
})
export class ExhibitionFrontPageComponent implements OnInit {
    public responsiveOptions: any[];

    public exhibitions: Exhibition[];
    public exhibition!: Exhibition | undefined;
    public page!: Page;
    public languages: SelectItem[];
    public selectedLanguage: string;

    constructor(public exhibitionService: ExhibitionService) {
        this.responsiveOptions = responsiveOptions;

        this.exhibitions = [];

        this.languages = [];
        this.selectedLanguage = '';
    }

    ngOnInit(): void {
        this.exhibitionService.getPublishedIntroductioryPagesForExhibition().subscribe((exhibitions) => {
            this.exhibitions = exhibitions;
            this.exhibition = this.exhibitions.length ? this.exhibitions[0] : undefined;
            if (this.exhibitions.length) {
                this.languages = this.exhibitions[0].pages.map((page: Page) => ({
                    label: page.language,
                    value: page.language
                }));
                this.selectedLanguage = this.languages[0].value;
                this.page = this.exhibitions[0].pages.filter((page: Page) => page.language === this.selectedLanguage)[0];
            }
        });
    }

    visibleChange(index: number) {
        this.exhibition = this.exhibitions[index];
        this.languages = this.exhibition.pages.map((page: Page) => ({
            label: page.language,
            value: page.language
        }));
        this.selectedLanguage = this.languages[0].value;
        this.page = this.exhibition.pages.filter((page: Page) => page.language === this.selectedLanguage)[0];
    }

    languageChagned(language: string) {
        this.selectedLanguage = language;
        if (this.exhibition) {
            this.page = this.exhibition.pages.filter((page: Page) => page.language === this.selectedLanguage)[0];
        }
    }
}
