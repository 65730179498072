<p-accordionTab [selected]="false" header="Детаљи Изложбе" style="margin-bottom: 0">
    <div class="p-fluid p-formgrid p-grid">
        <p class="titleLabel">Наслов</p>
        <div class="p-field p-col-12 p-md-12">
            <span class="p-float-label">
                <input [ngModel]="title$ | async" (ngModelChange)="updateTitle($event)" type="text" pInputText />
                <label for="title">Наслов</label>
            </span>
        </div>
        <!--<div class="titleContent" class="p-field p-col-12 p-md-12" [ngStyle]="{ overflow: 'auto', height: '106px' }">
            <ckeditor
                #titleEditor
                [ngStyle]="{ overflow: 'auto' }"
                [editor]="editorTitle"
                [config]="configTitleAndSubtitle"
                [data]="(title$ | async) || ''"
                (change)="updateTitle()"
                ngClass="ck-content"
            ></ckeditor>
        </div>-->
        <p class="subtitleLabel">Поднаслов</p>
        <div class="subtitleContent" class="p-field p-col-12 p-md-12">
            <span class="p-float-label">
                <input [ngModel]="subtitle$ | async" (ngModelChange)="updateSubtitle($event)" type="text" pInputText />
                <label for="title">Поднаслов</label>
            </span>
            <!--<ckeditor
                #subtitleEditor
                [ngStyle]="{ overflow: 'auto' }"
                [editor]="editorSubtitle"
                [config]="configTitleAndSubtitle"
                [data]="(subtitle$ | async) || ''"
                (change)="updateSubtitle()"
                ngClass="ck-content"
            ></ckeditor>-->
        </div>
        <p class="noteLabel">Белешка</p>
        <div class="p-field p-col-12 p-md-12">
            <textarea
                [ngModel]="note$ | async"
                (ngModelChange)="updateNote($event)"
                style="resize: none"
                id="float-input"
                rows="5"
                cols="30"
                pInputTextarea
            ></textarea>
        </div>
    </div>
    <div class="p-fluid outer">
        <div class="p-field inner">
            <ng-container *ngIf="thumbnail$ | async as thumbnail; else alternateThumbnail">
                <img style="max-width: 100%" [src]="thumbnail.file" />
            </ng-container>
            <ng-template #alternateThumbnail>
                <img style="max-width: 100%" src="/assets/images/thumbnail/placeholder-thumbnail.png" />
            </ng-template>
        </div>
        <div class="p-field">
            <button
                (click)="uploadThumbnail()"
                icon="pi pi-plus-circle"
                class="p-button-info"
                type="button"
                label="Додај насловну слику"
                pButton
                pRipple
            ></button>
        </div>
    </div>
    <div class="p-fluid">
        <button
            (click)="openBackgroundDialog()"
            icon="pi pi-plus-circle"
            class="p-button-info"
            type="button"
            label="Додај позадину изложби"
            pButton
            pRipple
        ></button>
    </div>
</p-accordionTab>

<app-upload-dialog
    *ngIf="uploadThumbnailDialog"
    [selectedOption]="'image'"
    [selectionDisabled]="true"
    (fileSelected)="changeThumbnailContent($event)"
    (closeDialog)="closeUploadDialog()"
></app-upload-dialog>

<app-background-dialog
    *ngIf="backgroundDialog"
    [backgroundImageFor]="'exhibition'"
    (closeDialog)="closeBackgroundDialog()"
></app-background-dialog>
