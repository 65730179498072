<p-contextMenu
    #cm
    [model]="menuItems"
    [style]="{ 'background-color': '#FAE7CC', 'border-radius': '6px', 'max-height': '200px', overflow: 'scroll' }"
>
</p-contextMenu>

<div *ngIf="page; else loadingPage">
    <div class="card" style="background-color: #fae7cc">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-1">
                <span class="p-float-label">
                    <input
                        [disabled]="page.gallery === true"
                        [ngModel]="page.row_number"
                        (ngModelChange)="rowChange($event, page)"
                        id="rows"
                        type="number"
                        pInputText
                    />
                    <label for="rows">Редови</label>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-3 label">Управљање величином и позицијом секција</div>
            <div class="p-field p-col-12 p-md-2">
                <button
                    [disabled]="page.gallery === true"
                    (click)="addSection(page.sections.length + 1)"
                    class="p-button-info"
                    type="button"
                    label="Додај секцију"
                    icon="pi pi-plus-circle"
                    pButton
                    pRipple
                ></button>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <button
                    #change
                    [disabled]="selectedSection === 0 || page.gallery === true"
                    (click)="addTextContent()"
                    type="button"
                    class="p-button-info"
                    icon="pi pi-pencil"
                    label="Додај текстуални садржај"
                    pButton
                    pRipple
                ></button>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <button
                    [disabled]="selectedSection === 0 || selectedSectionType !== 'text' || page.gallery === true"
                    (click)="openSectionBackgroundDialog()"
                    type="button"
                    class="p-button-info"
                    icon="pi pi-paperclip"
                    label="Додај позадину текстуалном садржају"
                    pButton
                    pRipple
                ></button>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <button
                    [disabled]="selectedSection === 0 || page.gallery === true"
                    (click)="openUploadDialog()"
                    type="button"
                    class="p-button-info"
                    icon="pi pi-paperclip"
                    label="Додај остали садржај"
                    pButton
                    pRipple
                ></button>
            </div>

            <div class="p-field p-col-12 p-md-1">
                <span class="p-float-label">
                    <input
                        [disabled]="page.gallery === true"
                        [ngModel]="page.column_number"
                        (ngModelChange)="columnChange($event, page)"
                        id="rows"
                        type="number"
                        pInputText
                    />
                    <label for="rows">Колоне</label>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-3 section-edit">
                <span class="p-float-label">
                    <p-inputSwitch
                        [disabled]="page.gallery === true"
                        [(ngModel)]="editable"
                        (ngModelChange)="updateEditable($event)"
                        id="editableSwitch"
                    ></p-inputSwitch>
                </span>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <button
                    [disabled]="selectedSection === 0"
                    (click)="openContextMenu($event, cm)"
                    type="button"
                    class="p-button"
                    icon="pi pi-list"
                    label="Промени редослед секција"
                    pButton
                    pRipple
                ></button>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <button
                    [disabled]="page.gallery === true"
                    (click)="deleteAllSectionContent(page.sections)"
                    class="p-button-info"
                    type="button"
                    label="Избриши садржај свих секција"
                    icon="pi pi-trash"
                    pButton
                    pRipple
                ></button>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <button
                    (click)="deleteSection(page.sections)"
                    pButton
                    class="p-button-info"
                    pRipple
                    type="button"
                    label="Уклони означену секцију"
                    icon="pi pi-trash"
                    [disabled]="selectedSection === 0"
                ></button>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <button
                    [disabled]="selectedSection === 0 || page.gallery === true"
                    (click)="deleteSectionContent(page.sections)"
                    class="p-button-info"
                    type="button"
                    label="Избриши садржај означене секције"
                    icon="pi pi-trash"
                    pButton
                    pRipple
                ></button>
            </div>
        </div>
    </div>
</div>

<ng-template #loadingPage>
    <div class="card" style="background-color: #fae7cc">
        <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12 p-md-1">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-1">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-3">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <p-skeleton height="39px"></p-skeleton>
            </div>
            <div class="p-field p-col-12 p-md-2">
                <p-skeleton height="39px"></p-skeleton>
            </div>
        </div>
    </div>
</ng-template>

<app-upload-dialog
    *ngIf="uploadFileDialog"
    [selectedOption]="'image'"
    [selectionDisabled]="false"
    (fileSelected)="changeSectionContent($event)"
    (closeDialog)="closeUploadDialog()"
></app-upload-dialog>

<app-background-dialog
    *ngIf="sectionBackgroundDialog"
    [backgroundImageFor]="'section'"
    (closeDialog)="closeSectionBackgroundDialog()"
></app-background-dialog>
