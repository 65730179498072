import { Component, OnInit } from '@angular/core';
import { StatisticsService } from '../../services/statistics.service';
import { Statistic } from 'src/modules/shared/model/statistic';
import { StatisticByCountry } from 'src/modules/shared/model/statistic-by-country';

@Component({
    selector: 'app-statistics',
    templateUrl: './statistics.component.html',
    styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {
    public downloadStatistic: Statistic[];
    public visitStatistic: Statistic[];
    public downloadByCountryStatistic: StatisticByCountry[];

    constructor(public statisticsService: StatisticsService) {
        this.downloadStatistic = [];
        this.visitStatistic = [];
        this.downloadByCountryStatistic = [];
    }

    ngOnInit(): void {
        this.statisticsService.getDownloadStatistic().subscribe((downloadStatistic) => {
            this.downloadStatistic = downloadStatistic;
        });

        this.statisticsService.getVisitStatistic().subscribe((visitStatistic) => {
            this.visitStatistic = visitStatistic;
        });

        this.statisticsService.getDownloadsByCountryStatistic().subscribe((downloadByCountryStatistic) => {
            this.downloadByCountryStatistic = downloadByCountryStatistic;
        });
    }
}
