import { Routes } from '@angular/router';
import { ExhibitionListComponent } from './pages/exhibition-list/exhibition-list.component';
import { ExhibitionFormComponent } from './pages/exhibition-form/exhibition-form.component';
import { AuthGuard } from '../shared/guards/auth.guard';
import { ExhibitionFrontPageComponent } from './pages/exhibition-front-page/exhibition-front-page.component';
import { ExhibitionViewComponent } from './pages/exhibition-view/exhibition-view.component';

export const ExhibitionRoutes: Routes = [
    { path: 'pocetna', pathMatch: 'full', component: ExhibitionFrontPageComponent },
    { path: '', pathMatch: 'full', component: ExhibitionListComponent, canActivate: [AuthGuard] },
    { path: 'dodaj', pathMatch: 'full', component: ExhibitionFormComponent, canActivate: [AuthGuard] },
    { path: 'izmena/:id', pathMatch: 'full', component: ExhibitionFormComponent, canActivate: [AuthGuard] },
    { path: 'pregled/:id', pathMatch: 'full', component: ExhibitionViewComponent, canActivate: [AuthGuard] }
];
