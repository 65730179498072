import { StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import * as _ from 'lodash';
import { Page } from 'src/modules/shared/model/page';
import { Section } from 'src/modules/shared/model/section';
import { ExhibitionStateModel } from '../exhibition.state';
import { RemoveSection } from '../exhibition.state.actions';

export const removeSection = (ctx: StateContext<ExhibitionStateModel>, action: RemoveSection) => {
    const state = ctx.getState();
    const sections: Section[] = _.cloneDeep(action.payload.sections)
        .filter((section) => section.section_order !== state.selectedSection)
        .sort((section1: Section, section2: Section) => {
            if (section1.section_order > section2.section_order) {
                return 1;
            }
            if (section1.section_order < section2.section_order) {
                return -1;
            }
            return 0;
        })
        .map((section, index) => {
            section.section_order = index + 1;
            return section;
        });
    ctx.setState(
        patch({
            selectedSection: 0,
            pages: updateItem<Page>(
                (item) => item?.page_number === state.selectedPage && item?.language === state.selectedLanguage,
                patch({ sections })
            )
        })
    );
};
