<div class="p-grid">
    <div class="p-col-12">
        <div class="card custom-color">
            <p-table
                #dt
                [value]="messages"
                [rows]="10"
                [columns]="columns"
                [loading]="loading"
                [rowHover]="true"
                dataKey="id"
                styleClass="p-datatable-messages"
            >
                <ng-template pTemplate="caption">
                    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                        <h5 class="p-m-0">Приказ пристиглих порука</h5>
                        <span class="p-input-icon-left">
                            <p-dropdown [options]="sortingOptions" [ngModel]="sorting" (onChange)="customSort($event)"></p-dropdown>

                            <input
                                [(ngModel)]="searchValue"
                                placeholder="Текст за претрагу"
                                type="text"
                                pInputText
                                style="margin-right: 20px"
                            />
                            <button (click)="search()" label="Претражи" type="button" pButton pRipple></button>
                        </span>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th>Име и презиме</th>
                        <th>Емаил</th>
                        <th>Датум слања</th>
                        <th>Датум одговора</th>
                        <th>Порука</th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-contactMessage>
                    <tr>
                        <td>
                            {{ contactMessage.name_and_surname }}
                        </td>
                        <td>
                            {{ contactMessage.email }}
                        </td>
                        <td>
                            {{ contactMessage.submitted | date: 'medium' }}
                        </td>
                        <td *ngIf="contactMessage.replied; else notReplied">
                            {{ contactMessage.replied | date: 'medium' }}
                        </td>
                        <ng-template #notReplied>
                            <td>Још није одговорено на поруку</td>
                        </ng-template>
                        <td>
                            {{ contactMessage.message }}
                        </td>
                        <td class="controls" style="text-align: center">
                            <button
                                *ngIf="!contactMessage.replied"
                                pButton
                                pRipple
                                icon="pi pi-pencil"
                                class="p-button-rounded p-button-info p-mr-2"
                                (click)="respond(contactMessage)"
                            ></button>
                            <button
                                *ngIf="contactMessage.replied"
                                pButton
                                pRipple
                                icon="pi pi-eye"
                                class="p-button-rounded p-button-info p-mr-2"
                                (click)="showRespondedMessage(contactMessage)"
                            ></button>
                            <button
                                pButton
                                pRipple
                                icon="pi pi-trash"
                                class="p-button-rounded p-button-info"
                                (click)="deleteMessage(contactMessage)"
                            ></button>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="summary">
                    <div>
                        <p-paginator #paginator [rows]="10" [totalRecords]="totalRecords" (onPageChange)="paginate($event)"></p-paginator>
                    </div>
                </ng-template>
            </p-table>
        </div>

        <app-send-email-dialog
            [message]="messageToBeSent"
            [visible]="sendEmailDialog"
            (responded)="responded($event)"
            (closeDialog)="closeEmailDialog($event)"
        ></app-send-email-dialog>

        <p-dialog [style]="{ width: '40vw' }" [(visible)]="respondedMessageDialog">
            <ng-template pTemplate="header">
                Наслов поруке: {{ respondedMessage.title }}
                <hr
            /></ng-template>
            Послато: {{ respondedMessage.replied | date: 'medium' }}
            <br />
            <hr />
            {{ respondedMessage.message }}
            <p-footer>
                <button (click)="closeRespondedMessageDialog()" type="button" label="Затвори" class="p-button" pButton></button>
            </p-footer>
        </p-dialog>
    </div>
</div>
